import {EXTENTION_ID} from '@/store/conf/api';
import {detect} from 'detect-browser';
import {log} from '@/utils/log';

import mobileDetect from 'mobile-device-detect';

declare let chrome: any;
let port;
const BADGE_MESSAGE = 'BADGE_MESSAGE';


export const sendMessageToExtention = (type: string, value?: any) => {
    // @ts-ignore
    if (!isChromium() || window.Cypress || isEmbedInTeams()) {
        return;
    }
    if (chrome !== undefined &&  chrome.runtime!=undefined) {
        try {
            if (port === undefined) {
                port = chrome.runtime.connect(EXTENTION_ID);
            }
            port.postMessage({type, value});
        } catch (e: any) {
            log.debug(e.message, 'for message ');
        }
    }
};

export const sendBadgeMessage = (value: string, color: string) => {
    sendMessageToExtention(BADGE_MESSAGE, {value, color});
};

export const openExtentionSlack = () => {
    open('https://slack.com/apps/A01G8UAGFQ9-weet', '_blank');
};

export const openExtentionTeams = () => {
    open('https://appsource.microsoft.com/cs-cz/product/office/WA200003284', '_blank');
};
export const openExtentionStore = async () => {
    // open the window with native method, proxying all args
    let width = Math.round(window.innerWidth / 2);
    if (width < 1024) {
        if (window.innerWidth <= 1024) {
            width = window.innerWidth;
        } else {
            width = 1024;
        }
    }
    const params = `height=${window.outerHeight},width=${width},
  location=yes,menubar=no,resizable=true,scrollbars=true,
  top=${window.top},left=${window.screenLeft}`;
    const url = 'https://chrome.google.com/webstore/detail/weeter-extention-qa/' + EXTENTION_ID + '?hl=en';
    const win = window.open(url, '_blank', params);

    if (win) {
        win.addEventListener('load', () => {
            return win;
        }, {
            once: true,
        });
    } else {
        return;
    }
};


export const isScreenAivailableWithoutExt = () => {
    const browser = detect();
    if (browser === null) {
        return false;
    }
    if (browser.name === 'chrome' && parseInt(browser.version, 10) > 71) {
        return true;
    }
    if (browser.name === 'firefox' && parseInt(browser.version, 10) > 66) {
        return true;
    }
    if (browser.name === 'edge-chromium') {
        return true;
    }
    return false;
};

/**
 * Return true if the app is currently running in Teams DESKTOP. And false otherwise.
 */
export const isEmbedInTeams = (): boolean => {
    return window.navigator.userAgent.includes('Teams');
};
export const isInIframe = (): boolean => {
    try {
        return window.self !== window.top;
    } catch (e) {
        return true;
    }
}


export const isChromium = (): boolean => {
    const browser = detect();
    if (browser === null) {
        return false;
    }

    if (browser.name === 'chrome') {
        return true;
    }
    if (browser.name === 'edge-chromium') {
        return true;
    }
    return false;
};

export const isMobile = (): boolean => {
    return mobileDetect.isMobile;
};


export const isTablet = (): boolean => {
    return mobileDetect.isTablet || mobileDetect.isSmartTV;
};

export const isTabletOrMobile = (): boolean => {
    return isMobile() || isTablet();
};


export const isPluginInstalled = (): Promise<boolean> => {
    return new Promise((resolve, reject) => {
        const img = new Image();
        img.src = 'chrome-extension://' + EXTENTION_ID + '/icons/icon_128_v2.png';
        img.onload = () => {
            resolve(true);
        };
        img.onerror = () => {
            resolve(false);
        };
    });
};

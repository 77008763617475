<template>
  <div class="weetAdvancedEditingVoiceOverContainer">
    <!-- container CountDown -->
    <div class="countDownContainer" v-if="step==30 && countDown>=-1">
      <div class="textCountDown" v-show="countDown>0">{{ countDown }}</div>
      <transition name="fade">
        <div class="startCountDownMessage" v-show="countDown==0">{{ $t('createWeetComponent.advancedEditing.voiceOverMode.record.go') }}</div>
      </transition>
    </div>

    <!-- STEP FIRST -->
    <div  v-if="step<10">
      <div class="titleVoiceOver">{{$t('createWeetComponent.advancedEditing.voiceOverMode.title')}}</div>
      <div class="subtitleVoiceOver">{{$t('createWeetComponent.advancedEditing.voiceOverMode.subtitle')}}</div>
      <div class="pleaseSelectSection"><div>{{$t('createWeetComponent.advancedEditing.voiceOverMode.selectSection')}}</div>
        <w-icon icon="arrow-down-circle-outline" color="light2" /> </div>
      <div class="bottomBarContainer">
        <TimeLine :advanced-aivailable="true"
                  :rearrange="false"
                  :buttonLabel="$t('createWeetComponent.advancedEditing.voiceOverMode.selection')"
                  :autoselectIfAlone="true"
                  @action="onSectionSelected"/>
      </div>
    </div>
    <!-- STEP SECOND -->
    <div v-if="step===10" >

      <div class="titleVoiceOver"><div class="backButton" @click="backMode" v-if="!modeIntegration"><w-icon size="small" icon="arrow-left" /></div> {{$t('createWeetComponent.advancedEditing.voiceOverMode.chooseMode.title')}}</div>
      <div class="subtitleVoiceOver">{{$t('createWeetComponent.advancedEditing.voiceOverMode.chooseMode.subtitle')}}</div>
      <div class="barChooseAIMic">
        <w-button color="outlined" size="medium" icon="creation-outline" @click="selectAIVoice" v-if="voiceOverAiEnabled"
        :tooltip="$t('createWeetComponent.advancedEditing.voiceOverMode.buttons.iaVoiceToolTip')">{{$t('createWeetComponent.advancedEditing.voiceOverMode.buttons.iaVoice')}}</w-button>
        <w-button  color="primary" size="medium" icon="microphone-outline" @click="onChooseMicVoice"
        :tooltip="$t('createWeetComponent.advancedEditing.voiceOverMode.buttons.micToolTip')">{{$t('createWeetComponent.advancedEditing.voiceOverMode.buttons.mic')}}</w-button>
      </div>
    </div>
    <!-- choose txt or subtitle -->
    <div v-if="step===15">
      <div class="titleVoiceOver"><div class="backButton" @click="backMode"><w-icon size="small" icon="arrow-left" /></div> {{$t('createWeetComponent.advancedEditing.voiceOverMode.chooseMode.title')}}</div>
      <div class="subtitleVoiceOver">{{$t('createWeetComponent.advancedEditing.voiceOverMode.chooseMode.subtitle')}}</div>
      <div class="barChooseAIMic">
        <w-button color="outlined" size="medium" icon="creation-outline" @click="selectTxtVoice" v-if="voiceOverAiEnabled"
                  :tooltip="$t('createWeetComponent.advancedEditing.voiceOverMode.buttons.iaVoiceToolTip')">{{$t('createWeetComponent.advancedEditing.voiceOverMode.buttons.fromTxt')}}</w-button>
        <b-dropdown aria-role="list">
          <template #trigger="{ active }">
            <w-button  color="outlined" size="medium"  :icon-right="active ? 'chevron-up' : 'chevron-down'"
                       :tooltip="$t('createWeetComponent.advancedEditing.voiceOverMode.buttons.iaSubtitleToolTip')">
              {{$t('createWeetComponent.advancedEditing.voiceOverMode.buttons.fromSubtitle')}}
            </w-button>
          </template>
          <b-dropdown-item aria-role="listitem" v-if="isTranscriptEnabled" @click="onTranscriptSelected"><w-icon color="dark" size="small" icon="creation-outline"/> {{$t('createWeetComponent.advancedEditing.voiceOverMode.buttons.generateSubtitle')}}</b-dropdown-item>
          <b-dropdown-item aria-role="listitem"  v-for="sub in weet.subtitles"
                           :key="sub.subtitleID+'_voiceOverSubtitleEditing' "
                           @click="onSubtitleAIVoice(sub)">{{ sub.label }}</b-dropdown-item>
        </b-dropdown>
      </div>
    </div>
    <!-- Mode voice Text AI -->
    <div v-if="step===20">
      <div class="titleVoiceOver"><div class="backButton" @click="selectChooseMode"><w-icon size="small" icon="arrow-left" /></div> {{$t('createWeetComponent.advancedEditing.voiceOverMode.aivoice.text.title')}}</div>
      <div class="subtitleVoiceOver">{{$t('createWeetComponent.advancedEditing.voiceOverMode.aivoice.text.subtitle')}}</div>
      <div class="textareaContainerAIVoice">
        <w-input type="textarea" class="textareaAIVoice" maxlength="3000"
                 @input="eraseBlobPreview" v-model="textVoiceOver"></w-input>
      </div>
      <div class="barValidateTextAI">
        <div class="lineLeft">
          <div class="labelVoices">{{$t('createWeetComponent.advancedEditing.voiceOverMode.voices')}}</div>
          <w-select class="paramsSelect" v-model="voiceSelected"  >
            <option v-for="voice in listOfVoice" :value="voice.value" :key="'voice_ai_'+voice.value">
              {{ voice.label }}
            </option>
          </w-select>
        </div>

        <div class="lineRight">
          <w-button color="text" size="medium" class="buttonEditing" @click="selectChooseMode">{{$t('createWeetComponent.advancedEditing.voiceOverMode.buttons.aicancel')}}</w-button>
          <w-button color="outlined" size="medium" class="buttonEditing" :loading="loading" :enabled="textVoiceOver" icon="play" @click="getSoundForText">{{$t('createWeetComponent.advancedEditing.voiceOverMode.buttons.aipreview')}}</w-button>
          <w-button  color="primary" size="medium" class="buttonEditing" :loading="loading && !!audioToUpload" :enabled="!!audioToUpload" icon="check" @click="doVoiceOver">{{$t('createWeetComponent.advancedEditing.voiceOverMode.buttons.validateAIVoice')}}</w-button>
        </div>
      </div>
    </div>

    <!-- Mode voice Subtitle AI -->
    <div v-if="step===25">
      <div class="titleVoiceOver"><div class="backButton" @click="selectChooseMode"><w-icon size="small" icon="arrow-left" /></div> {{$t('createWeetComponent.advancedEditing.voiceOverMode.aivoice.subtitle.title')}}</div>
      <div class="subtitleVoiceOver">{{$t('createWeetComponent.advancedEditing.voiceOverMode.aivoice.subtitle.subtitle')}}</div>
      <div class="barValidateTextAI">
        <div class="lineLeft">
          <div class="labelVoices">{{$t('createWeetComponent.advancedEditing.voiceOverMode.voices')}}</div>
          <w-select class="paramsSelect" v-model="voiceSelected"  >
            <option v-for="voice in listOfVoice" :value="voice.value" :key="'voice_ai_'+voice.value">
              {{ voice.label }}
            </option>
          </w-select>
        </div>
        <div class="lineRight">
          <w-button color="outlined" size="medium" class="buttonEditing" :loading="loading" :enabled="subtitleSelected" icon="play" @click="getSoundForSubtitle()">{{$t('createWeetComponent.advancedEditing.voiceOverMode.buttons.aipreview')}}</w-button>
          <w-button  color="primary" size="medium" class="buttonEditing" :loading="loading && !!audioToUpload" :enabled="!!audioToUpload" icon="check" @click="doVoiceOver">{{$t('createWeetComponent.advancedEditing.voiceOverMode.buttons.validateAIVoice')}}</w-button>
        </div>
      </div>
    </div>

    <!-- Microphone recording -->
    <div v-if="step===30" >
      <div class="titleVoiceOver"><div class="backButton" @click="selectChooseMode"><w-icon size="small" icon="arrow-left" /></div> {{$t('createWeetComponent.advancedEditing.voiceOverMode.microphone.title')}}</div>
      <div class="subtitleVoiceOver">{{$t('createWeetComponent.advancedEditing.voiceOverMode.microphone.subtitle')}}</div>
      <div class="recordingProgressBar">
        <div class="recordingProgress" :style="progressRecordStyle"></div>
      </div>
      <div class="recordingBar">
        <div class="recordingTime">
          <w-icon icon="record" color="danger" size="default"/>
          {{ timeRecordedFormat }}
        </div>
        <div class="spacer"></div>
        <w-button class="stopPausePlayButton" color="outlined" @click="pauseMicrophoneRecording"
                         :disabled="!isRecording" v-if="!isRecordingPause" icon="pause">
          {{ $t('createWeetComponent.advancedEditing.voiceOverMode.record.buttons.pause') }}
        </w-button>
        <w-button class="stopPausePlayButton" color="outlined" @click="resumeMicrophoneRecording"
                         :disabled="!isRecording" v-if="isRecordingPause" icon="play">
          {{ $t('createWeetComponent.advancedEditing.voiceOverMode.record.buttons.resume') }}
        </w-button>
        <w-button class="stopPausePlayButton" @click="stopMicrophoneRecording"
                         :disabled="!isRecording" icon="stop">
          {{ $t('createWeetComponent.advancedEditing.voiceOverMode.record.buttons.stop') }}
        </w-button>
        <!-- mediaRecorder -->
        <MediaRecorder ref="mediaRecorder" :countdown="3" @onBlobRecorded="onSuccessMicrophoneRecorded"
                       :with-button="false"
                       @onError="onMicrophoneError" @onCountDown="onCountDown" @onStart="onStartRecording"
                       @onTimeRecord="onTimeRecord"/>
      </div>

    </div>
    <!-- onRecording step 11-->
    <div v-if="step==31">
      <div class="titleVoiceOver"><div class="backButton" @click="selectChooseMode"><w-icon size="small" icon="arrow-left" /></div> {{$t('createWeetComponent.advancedEditing.voiceOverMode.microphone.title')}}</div>
      <div class="subtitleVoiceOver">{{$t('createWeetComponent.advancedEditing.voiceOverMode.microphone.subtitle')}}</div>
      <!-- preview record microphone step 31 -->
        <div class="restartContainer">
          <w-button class="buttonRestart" color="outined" icon="record-circle-outline" @click="onChooseMicVoice">
            {{ $t('createWeetComponent.advancedEditing.voiceOverMode.record.buttons.restart') }}
          </w-button>
          <w-button class="buttonConfirmMicrophone" color="outined" @click="cancelMicrophoneRecord">
            {{ $t('createWeetComponent.advancedEditing.voiceOverMode.record.buttons.cancel') }}
          </w-button>
          <w-button class="buttonConfirmMicrophone" @click="doVoiceOver" :enabled="!loading" :loading="loading">
            {{ $t('createWeetComponent.advancedEditing.voiceOverMode.record.buttons.confirm') }}
          </w-button>
        </div>
    </div>
  </div>
</template>

<script lang="ts">
import {Component, Emit, Prop, Vue} from 'vue-property-decorator';
import WeetPlayer from '@/components/play/player/WeetPlayer.vue';
import WInput from '@/components/wrapper/w-input.vue';
import WError from '@/components/wrapper/w-error.vue';
import {Weet} from '@/store/weet/weetModel';
import WButton from '@/components/wrapper/w-button.vue';
import EndWeetButton from '@/components/actionButton/EndWeetButton.vue';
import TimeLine from '@/components/recorder/timeLine/TimeLine.vue';
import TimeLineSelector from '@/components/recorder/timeLine/TimeLineSelector.vue';
import ProgressProcessPlayer from '@/components/play/process/ProgressProcessPlayer.vue';
import WSwitch from '@/components/wrapper/w-switch.vue';
import UpgradePlanTag from '@/components/upgradePlan/UpgradePlanTag.vue';
import {Segment} from "@/store/timeLine/timeEvent";
import WIcon from "@/components/wrapper/w-icon.vue";
import WSelect from "@/components/wrapper/w-select.vue";
import {
  AvatarVoiceAvatar, Text2SpeachParams,
  VoiceOverParams
} from "@/store/AIAvatar/aiavatarGenerationModel";
import store from "@/store";
import {SRT_2_SPEECH_GENERATE, TEXT_2_SPEECH_GENERATE, VOICE_OVER_MEDIA} from "@/store/media/mediaAction";
import {base64ToBlob} from "@/utils/audioUtil";
import {PLAYER_PLAYING, SEEK_PLAYER} from "@/store/timeLine/timeLineAction";
import delay from "delay";
import {listOfVoice} from "@/utils/voiceUtil";
import prettyMilliseconds from "pretty-ms";
import MediaRecorder from "@/components/recorder/mediaRecorder/MediaRecorder.vue";
import {CHANGE_PLAYER_SPEED, CHANGE_PLAYER_VOLUME} from "@/store/persistedInformation/persistAction";
import {SELECT_MODE, SET_VOICE_OVER_PREVIEW} from "@/store/advancedEditing/advancedEditingAction";
import {AdvancedEditingMode} from "@/enum/AdvancedEditingMode";
import {Subtitle} from "@/store/subtitles/SubtitleModel";
import {getUrlContent} from "@/utils/util";
import srtParser2 from "srt-parser-2";
import {extractPartialSrtContent, extractSrtContent} from "@/utils/srtUtils";
import {REFRESH_WEET_FOR_EDITING} from "@/store/myWeet/myWeetAction";


@Component({
  components: {
    MediaRecorder,
    WSelect,
    WIcon,
    UpgradePlanTag,
    WSwitch,
    ProgressProcessPlayer, TimeLineSelector, TimeLine, EndWeetButton, WButton, WError, WInput, WeetPlayer
  },
})
export default class CreateWeetVoiceOverMode extends Vue {

  @Prop()
  private weet!: Weet;

  @Prop({default:false})
  private modeIntegration!: boolean;

  // step 0 -9 select section
  // step 10 - 19 select mode
  // step 20 - 29 voice IA
  // step 30 - 39 voice Over microphone
  private step:number=0;
  // current time recorded when record with mediaRecorder
  private timeRecorded: number = 0;
  // Countdown before record
  private countDown=-1;
  private voiceSelected=AvatarVoiceAvatar.EN_US_AMANDA // Default voice
  private sectionSelected:Segment|null=null;
  private textVoiceOver=""; // text to generate with AI
  private subtitleSelected:Subtitle|null=null;
  private loading=false;
  private audioToUpload:Blob|null=null; // audio to upload to voiceOver Action
  // state of the record
  private isRecording: boolean = false;
  private isRecordingPause = false;


  /**
   * Get pourcentage of progress
   */
  get progressRecord(): number {
    let pour= this.timeRecorded / this.durationVideo;
    if(pour>1){
      pour=1;
    }
    return pour;
  }

  get isTranscriptEnabled(){
    return store.getters.isTranscript;
  }

  get voiceOverAiEnabled():boolean{
    return store.getters.isVoiceOverAI;
  }

  get progressRecordStyle(): string {
    return `width:${this.progressRecord * 100}%`
  }
  get timeRecordedFormat(): string {
    return prettyMilliseconds(this.timeRecorded, {secondsDecimalDigits: 0, colonNotation: true})
  }

  get durationVideo(){
    if(this.sectionSelected){
      return this.sectionSelected.end.time - this.sectionSelected.start.time
    }
    return 0;
  }

  get listOfVoice() {
    return listOfVoice;
  }

  private mediaRecorder(): MediaRecorder | undefined {
    return this.$refs['mediaRecorder'] as MediaRecorder
  }

  private backMode(){
    store.dispatch(SET_VOICE_OVER_PREVIEW,false)
    this.step=0;
    this.sectionSelected=null;
    this.$emit('onSectionSelected',null)
  }

  private async selectChooseMode(){
    await this.changeAudioToSync(null);
    await delay(100);
    await store.dispatch(PLAYER_PLAYING,false);
    await store.dispatch(SET_VOICE_OVER_PREVIEW,false)
    this.step=10;
    this.loading=false;
    this.textVoiceOver="";
    this.audioToUpload=null;
  }

  private selectAIVoice(){
    this.step=15;
  }

  private selectTxtVoice(){
    this.step=20;
  }

  private onSubtitleAIVoice(sub:Subtitle){
    this.step=25;
    this.subtitleSelected=sub
  }

  private onTranscriptSelected(){
    store.dispatch(SELECT_MODE, AdvancedEditingMode.SUBTITLE);
  }

  private onChooseMicVoice() {
    this.step = 30;
    this.timeRecorded=0;
    this.changeAudioToSync(null);
    // we wait the component rendering
    this.$nextTick(() => {
      const mediaRecorder = this.mediaRecorder();
      if (mediaRecorder) {
        mediaRecorder.startRecording()
      } else {
        console.warn("Media recorder not found")
      }
    })
  }

  /**
   * When the medira recorder start to record the microphone
   * We mute the video plauyer, and launch the play
   */
  private onStartRecording(): void {
    this.countDown = -1;
    this.step = 30
    this.isRecording = true;
    store.dispatch(SEEK_PLAYER,this.sectionSelected?.start.time).then(()=>{
      store.dispatch(PLAYER_PLAYING,true);
    })
    store.dispatch(SET_VOICE_OVER_PREVIEW,true)
  }

  /**
   * Ask to the media recorder to pause the recording
   * stop the video player also
   * and wait for blob finaliszing (see onSuccessMicrophoneRecorded)
   */
  private pauseMicrophoneRecording(): void {
    if (this.isRecording) {
      const mediaRecorder = this.mediaRecorder();
      if (mediaRecorder) {
        mediaRecorder.pauseRecording()
        this.isRecordingPause = true;
        // pause the player
        store.dispatch(PLAYER_PLAYING,false);
      } else {
        console.warn("Media recorder not found")
      }
    }
  }
  /**
   * Ask to the media recorder to resume the recording
   * Play the video player also
   * and wait for blob finaliszing (see onSuccessMicrophoneRecorded)
   */
  private resumeMicrophoneRecording(): void {
    if (this.isRecording) {
      const mediaRecorder = this.mediaRecorder();
      if (mediaRecorder) {
        mediaRecorder.resumeRecording()
        this.isRecordingPause = false
        // play the player
        store.dispatch(PLAYER_PLAYING,true);
      } else {
        console.warn("Media recorder not found")
      }
    }
  }

  /**
   * Ask to the media recorder to stop the recording
   * stop the video player also
   * and wait for blob finaliszing (see onSuccessMicrophoneRecorded)
   */

  private stopMicrophoneRecording(): void {
    if (this.isRecording) {
      const mediaRecorder = this.mediaRecorder();
      this.isRecording = false;
      if (mediaRecorder) {
        mediaRecorder.stopRecording()
        // play the player
        store.dispatch(PLAYER_PLAYING,false);
      } else {
        console.warn("Media recorder not found")
      }
    }
  }

  /**
   * When microphone blob is received from the mediarecorder
   * we change the step to ask for a confirmation
   * @param event
   * @param blob
   */

  private onSuccessMicrophoneRecorded(event: void, blob: Blob):void {
    this.audioToUpload = blob;
    this.step = 31; // preview Step
    if(this.sectionSelected){
      store.dispatch(SEEK_PLAYER,this.sectionSelected.start.time).then(async ()=>{
        if(this.audioToUpload) {
          const url = URL.createObjectURL(this.audioToUpload);
          this.changeAudioToSync(url);
          // wait a preload of the mp3
          await delay(500);
          // play the player
          store.dispatch(PLAYER_PLAYING, true);
        }
      })
      store.dispatch(SET_VOICE_OVER_PREVIEW,true)
    }
  }

  /**
   * The user cancel the record,
   * we reset the blob and come back to step 0
   */
  private cancelMicrophoneRecord(): void {
    const mediaRecoreder = this.mediaRecorder()
    if (mediaRecoreder) {
      mediaRecoreder.cancel()
    }
    this.audioToUpload = null;
    this.changeAudioToSync(null);
    this.step = 0;
    this.countDown = -1;
    store.dispatch(SET_VOICE_OVER_PREVIEW,false)
    store.dispatch(SEEK_PLAYER,this.sectionSelected?.start.time)
  }

  /**
   * When it's impossible to capture the microphone,
   * we rollback to step 0
   */
  private onMicrophoneError(): void {
    this.step = 0;
  }
  /**
   * save the value of current countdown before the microphone record
   * @param countDown
   */
  private onCountDown(countDown: number): void {
    this.countDown = countDown;
  }

  /**
   * Save the current time of the micriphone record
   * @param time
   */
  onTimeRecord(time: number): void {
    this.timeRecorded = time;
    store.dispatch(CHANGE_PLAYER_SPEED,1);
    if (this.timeRecorded > (this.durationVideo)) {
      this.stopMicrophoneRecording()
    }
  }


  /**
   * Get the bloc sound from the text
   * @private
   */
  private async getSoundForText(){
    this.loading=true;
    const text2SpeachParams=new Text2SpeachParams()
    text2SpeachParams.text=this.textVoiceOver;
    text2SpeachParams.voice=this.voiceSelected;
    const base64=await store.dispatch(TEXT_2_SPEECH_GENERATE,text2SpeachParams);
    this.audioToUpload=base64ToBlob(base64,'audio/mp3');
    const url = URL.createObjectURL(this.audioToUpload);
    this.changeAudioToSync(url);
    store.dispatch(PLAYER_PLAYING,false)
    // wait a preload of the mp3
    await delay(500);
    // put in the begining of the section and play
    store.dispatch(SEEK_PLAYER,this.sectionSelected?.start.time).then(async ()=>{
      await store.dispatch(SET_VOICE_OVER_PREVIEW,true)
      await delay(100);
      store.dispatch(PLAYER_PLAYING,true)
    });
    this.loading=false;
  }

  private eraseBlobPreview(){
    this.audioToUpload=null;
  }
  private async getSoundForSubtitle(){
    if(this.subtitleSelected && this.sectionSelected) {
      this.loading = true;
      // waiting
      const params = new Text2SpeachParams();
      params.voice=this.voiceSelected;
      params.text= await extractPartialSrtContent(this.subtitleSelected.url,
          this.sectionSelected.start.time,
          this.sectionSelected.end.time,
          this.sectionSelected.start.time,
      );
      const base64=await store.dispatch(SRT_2_SPEECH_GENERATE, params)
      this.audioToUpload=base64ToBlob(base64,'audio/mp3');
      const url = URL.createObjectURL(this.audioToUpload);
      this.changeAudioToSync(url);
      store.dispatch(PLAYER_PLAYING,false)
      // wait a preload of the mp3
      await delay(500);
      // put in the begining of the section and play
      store.dispatch(SEEK_PLAYER,this.sectionSelected?.start.time).then(async ()=>{
        store.dispatch(PLAYER_PLAYING,true)
        await delay(100);
        store.dispatch(SET_VOICE_OVER_PREVIEW,true)
      });
      this.loading=false;
    }
  }


  @Emit('onAudioToSync')
  private async changeAudioToSync(url:String|null ){
    return;
  }


  private async doVoiceOver(){
    if(!this.audioToUpload){
      return;
    }
    var timeEventStart=this.sectionSelected?.start;
    if(timeEventStart) {
      var mediaID=timeEventStart.mainMedia.mediaID;
      if(timeEventStart.secondMedia.mediaID){
        mediaID=timeEventStart.secondMedia.mediaID;
      }

      const voiceOverParam=new VoiceOverParams(this.audioToUpload,mediaID)
      this.changeAudioToSync(null);
      if(this.modeIntegration){
        await delay(500);
        this.$emit('back');
      }
      store.dispatch(VOICE_OVER_MEDIA, voiceOverParam).then(()=>{
        if(!this.modeIntegration){
          this.backMode()
        }
      })

    }
  }

  @Emit('onSectionSelected')
  private onSectionSelected(segment:Segment|null){
    this.sectionSelected=segment;
    this.selectChooseMode()
    return;
  }

  beforeDestroy(){
    store.dispatch(SET_VOICE_OVER_PREVIEW,false)
    this.changeAudioToSync("");
    this.onSectionSelected(null);
  }

}
</script>

<style lang="scss">
// no scope to manage textarea size
.textareaAIVoice{
  textarea.textarea{
    font-size:14px;
    min-height: 81px;
    max-height: 81px;
    resize: none;
  }
}


</style>
<style scoped lang="scss">

.weetAdvancedEditingVoiceOverContainer {
  margin: auto;
  height:238px;

  .titleVoiceOver{
    margin-top:8px;
    display: flex;
    padding-left: 16px;
    text-align: left;
    font-size: 20px;
    font-weight: 900;
    .backButton{
      margin-right: 8px;
      cursor: pointer;
    }
  }
  .subtitleVoiceOver{
    padding-left: 16px;
    color: var(--light2);
    text-align: left;
  }
  .pleaseSelectSection{
    position: absolute;
    width: 100%;
    text-align: center;
    top:432px;
    color: var(--dark);
    animation: blink 1.5s infinite;
  }

  .textareaContainerAIVoice{
    padding: 16px;
  }
  .bottomBarContainer{
    position: absolute;
    bottom: 0px;
    left: 0px;
    width: calc(100% - 0px);
    .barSections{
      width: 100%;
    }
  }
  .barChooseAIMic{
    position: absolute;
    bottom: 8px;
    width: 100%;
    display: flex;
    justify-content: space-between;
    padding:16px;
  }
  .barValidateTextAI{
    position: absolute;
    bottom: 8px;
    width: 100%;
    display: flex;
    justify-content: space-between;
    padding:16px;
    .lineLeft{
      display: flex;
      align-items: center;
      .labelVoices{
        margin-right: 16px;
      }
    }
    .lineRight{
      display: flex;
      .buttonEditing{
        padding-left: 16px;
      }
    }
  }

  .countDownContainer {
    position: absolute;
    top: 0px;
    width: 100%;
    height: 324px;
    background: rgba(10, 8, 47, 0.5);


    .textCountDownReady {
      position: absolute;
      top: 0px;
    }

    .textCountDown {
      font-weight: 900;
      font-size: 128px;
      display: flex;
      height: 100%;
      width: 100%;
      color: white;
      align-items: center;
      justify-content: center;
    }

    .startCountDownMessage {
      font-weight: 900;
      font-size: 128px;
      display: flex;
      height: 100%;
      width: 100%;
      color: white;
      align-items: center;
      justify-content: center;
    }
  }


  .recordingProgressBar {
    width: calc(100% - 32px);
    height: 4px;
    border-radius: 4px;
    background: var(--light1);
    margin-bottom: 16px;
    position: relative;
    margin-left: 16px;
    margin-top: 8px;

    .recordingProgress {
      position: absolute;
      top: 0px;
      left: 0px;
      border-radius: 4px;
      background: var(--primary);
      height: 4px;
      transition: width 0.5s linear;
    }
  }


  .recordingBar {
    display: flex;
    position: absolute;
    bottom: 16px;
    width: calc(100% - 32px);
    left: 16px;
    align-items: flex-end;
    .spacer {
      flex: 1;
    }
    .recordingTime {
      position: absolute;
      display: flex;
      left: 0px;
      letter-spacing: 1px;
      color: var(--dark);
      font-size: 14px;
      font-weight: 900;
      text-align: left;
      flex: 1;
      align-items: center;
    }

    .stopPausePlayButton {
      margin-left: 16px;
    }
  }

  .restartContainer {
    display: flex;
    flex: 1;
    text-align: left;
    position: absolute;
    width: calc(100% - 32px);
    bottom:16px;
    left: 16px;
    .buttonRestart{
      flex: 1;
    }
  }

  .buttonConfirmMicrophone {
    margin-left: 16px;
    margin-right: 0px;
  }


}
</style>

<template>
  <div class="buttonWrapper" :class="{upgrade:upgradeMode}" :style="expanded? 'width: 100%':''"
       @click="upgradeMode?moveOrGoToPayment():null">
    <w-tool-tip :label="tooltip"
                :size="tooltipSize"
                :color="tooltipColor"
                :position="tooltipPosition"
                :delay="tooltipDelay"
                :expanded="expanded"
    >
      <b-button :tag="tag"
                :data-test="dataTest"
                :rounded="rounded || circle"
                :size="'is-'+size"
                :icon-left="icon"
                :icon-right="iconRight"
                :type="'is-'+color"
                :class="customClass"
                :disabled="!isEnabled"
                :loading="loading"
                :expanded="expanded"
                v-on="$listeners"
                :stepTour="stepTour"

      >
        <slot></slot>
      </b-button>
      <div class="badge" v-if="badge!==undefined">{{ badge }}</div>
      <UpgradePlanTag v-if="upgradeMode" class="upgradePlanTag" size="small"/>
    </w-tool-tip>
  </div>
</template>

<script lang="ts">
import {Component, Prop, Vue, Watch} from 'vue-property-decorator';
import UpgradePlanTag from '@/components/upgradePlan/UpgradePlanTag.vue';
import {canMoveWeetFromCurrentWorkspace, hasPaidWorkspace} from '@/utils/workspaceUtil';
import {alertMessageWithConfirmation} from '@/utils/dialog';
import store from '@/store';
import {SHOW_POPUP_CREATION} from '@/store/recordingState/recordStateAction';
import router from '@/router';
import {Weet} from '@/store/weet/weetModel';
import {canEditThisWeet} from '@/utils/weetUtil';
import {STORE_LINK_REDIRECT_AFTER_PAYMENT} from "@/store/persistedInformation/persistAction";
import WToolTip from "@/components/wrapper/w-toolTip.vue";

@Component({
  components: {WToolTip, UpgradePlanTag},
})
export default class WButton extends Vue {
  @Prop({default: false})
  private rounded!: boolean;
  @Prop({default: 'default'})
  private size!: string;
  @Prop({default: ''})
  private icon!: string;
  @Prop({default: ''})
  private iconRight!: string;
  @Prop({default: 'primary'})
  private color!: string;
  @Prop({default: false})
  private circle!: boolean;
  @Prop({default: false})
  private noOutlineOnFocus!: boolean;
  @Prop({default: true})
  private enabled!: boolean;
  @Prop({default: false})
  private loading!: boolean;
  @Prop({default: false})
  private link!: boolean;

  @Prop({default: ''})
  private tooltip!: string;
  @Prop({default: 'top'})
  private tooltipPosition!: string;
  @Prop({default: 'small'})
  private tooltipSize!: string;
  @Prop({default: 'light'})
  private tooltipColor!: string;
  @Prop({default: 0})
  private tooltipDelay!: number;
  @Prop()
  private dataTest!: string;
  @Prop()
  private badge!: string;
  @Prop({default: false})
  private expanded!: boolean;
  @Prop({default: false})
  private pulse!: boolean;
  @Prop({default: ''})
  private stepTour!: string;
  @Prop({default: false})
  private justifySpaceBetween!: boolean;

  @Prop({default: false})
  private upgradeMode!: boolean;
  @Prop({required: false})
  private weet!: Weet;
  @Prop({default:false})
  private paymentButton!:boolean


  get isEnabled() {
    return this.enabled && (!this.upgradeMode || this.paymentButton);
  }


  get tag(): string {
    if (this.link) {
      return 'a';
    } else {
      return 'button';
    }
  }


  get customClass(): string {
    let classes = '';
    if (this.pulse) {
      classes += ' pulse ';
    }
    if (this.circle) {
      classes += 'circleButton';
    }
    if (this.noOutlineOnFocus) {
      classes += ' noOutlineOnFocus ';
    }
    if (this.justifySpaceBetween) {
      classes += ' justify-space-between ';
    }

    return classes;
  }


  get isEditable(): boolean {
    if (this.weet) {
      return canEditThisWeet(this.weet);
    } else {
      return false;
    }
  }

  get selectedWorkspaceID(): string {
    return store.getters.getSelectedWorkspace;
  }

  get canMoveWeet(): boolean {
    if (this.weet) {
      return this.isEditable && (this.weet.workspaces.length === 0 || canMoveWeetFromCurrentWorkspace());
    } else {
      return false;
    }
  }

  private async moveOrGoToPayment() {
    if (!this.weet) {
      return;
    }
    // if i'm in PS
    // and i have a paid workspace
    // and i can move my weet
    if (!this.selectedWorkspaceID && hasPaidWorkspace() && this.canMoveWeet) {
      await alertMessageWithConfirmation(
          this.$t('menuWeet.downloadPs.move.description').toString(),
          this.$t('menuWeet.downloadPs.move.title').toString(),
      );
      this.$emit('move');
    } else {
      if (store.getters.showPopUpCreation) {
        store.dispatch(SHOW_POPUP_CREATION, false);
      }
      store.dispatch(STORE_LINK_REDIRECT_AFTER_PAYMENT,document.location.href)
      router.push({name: 'MyWorkspacePayment'});
    }
  }

}
</script>

<style scoped lang="scss">
@import "@/scss/animation.scss";

.buttonWrapper {
  position: relative;

  &.upgrade {
    cursor: pointer;
  }

  .badge {
    background: red;
    position: absolute;
    height: 16px;
    width: 16px;
    top: -4px;
    right: -4px;
    color: white;
    font-size: 10px;
    border-radius: 16px;
    cursor: pointer;
  }

  .upgradePlanTag {
    position: absolute;
    top: -12px;
    right: -8px;
  }

  .noOutlineOnFocus {
    &:focus {
      outline: none;
      color: black;
      background: var(--light);
      border: none;
      border-radius: 0;
      box-shadow: none;
    }
  }

  .justify-space-between {
    justify-content: space-between;
  }

  // Pulse is desactivate becaus eof performance issue
  /*.pulse {*/
  /*  animation: pulse-primary 1.5s infinite ease-in-out;*/
  /*}*/


}

</style>

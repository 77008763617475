import { render, staticRenderFns } from "./MainVideoPlayer.vue?vue&type=template&id=6ffad939&scoped=true"
import script from "./MainVideoPlayer.vue?vue&type=script&lang=ts"
export * from "./MainVideoPlayer.vue?vue&type=script&lang=ts"
import style0 from "./MainVideoPlayer.vue?vue&type=style&index=0&id=6ffad939&prod&scoped=true&lang=scss"


/* normalize component */
import normalizer from "!../../../../../node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6ffad939",
  null
  
)

export default component.exports
<template>
  <transition name="fade">
    <div class="AiEditorContainer"
         v-show="streamLoading"
    >
      <b-carousel class="carrouselAvatars" :autoplay="false" :has-drag="false" :arrow="false" :indicator="false"
                  v-model="step">
        <b-carousel-item class="aiAvatarSelection">
          <div class="avatarBackground" :style="'background-image:url('+backgroundSelected.url+')'"></div>
          <div class="avatarPreview" :class="avatarSelectedClass"></div>
          <div class="avatarParameter">
            <div class="labelSelect">{{ $t('aiAvatar.styleSelector') }}</div>
            <b-dropdown :max-height="160" :position="'is-bottom-left'" :scrollable="true" >
              <template #trigger>
                <div
                    class="avatarSelector"
                    role="button">
                  <div class="avatarSelectorContent">
                    {{ getLabelForAvatar(avatarSelected,styleSelected) }}
                    <w-icon color="light2" icon="chevron-down"></w-icon>
                  </div>
                </div>
              </template>
              <b-dropdown-item v-for="(avatar,index) in listOfAvatar"
                               :key="'avatar_'+avatar.avatar+index" >
                <div class="avatarSelection" @click="onAvatarSelected(avatar)">
                  <span class="avatarSelectionImage" :style="'background-image:url('+avatar.img+')'" />
                  <span class="avatarSelectionLabel">
                     {{ avatar.label }}
                  </span>
                  <div class="avatarSelected" v-if="avatar.avatar===avatarSelected && avatar.style===styleSelected">
                    <w-icon icon="check"  color="primary" />
                  </div>
                </div>
              </b-dropdown-item>
            </b-dropdown>

            <div class="labelSelect">{{ $t('aiAvatar.background') }}</div>
            <b-dropdown :max-height="120" :position="'is-bottom-left'" :scrollable="true" >
              <template #trigger>
                <div
                    class="backgroundSelector"
                    role="button">
                  <div class="backgroundSelectorContent">
                    {{ backgroundSelected.label }}
                    <w-icon color="light2" icon="chevron-down"></w-icon>
                  </div>
                </div>
              </template>
              <b-dropdown-item v-for="background in listOfBackground"
                               :key="'background_'+background.background" >
                <div class="backgroundSelection" @click="onBackgroundSelected(background)">
                  <span class="backgroundSelectionImage" :style="'background-image:url('+background.url+')'" />
                  <span class="backgroundSelectionLabel">
                    {{ background.label }}
                  </span>
                  <div class="backgroundSelected" v-if="background===backgroundSelected">
                    <w-icon icon="check"  color="primary" />
                  </div>
                </div>

              </b-dropdown-item>
            </b-dropdown>
          </div>
        </b-carousel-item>
        <b-carousel-item class="aiAvatarContentEdition">
          <div class="labelTypeYourText">
            <w-button color="text" icon="arrow-left" size="small" class="buttonBack"
                      @click="backToFirstStep"></w-button>

            <div class="labelSelect">{{ $t('aiAvatar.langsSelector') }}</div>
            <w-select class="langSelector" v-model="langSelected" size="small">
              <option :value="listOfVoicesLangs[0].value" :key="'voice_lang_'+listOfVoicesLangs[0].value">
                {{ listOfVoicesLangs[0].label }}
              </option>
              <hr>
              <option v-for="lang in listOfVoicesLangs.slice(1)" :value="lang.value" :key="'voice_lang_'+lang.value">
                {{ lang.label }}
              </option>
            </w-select>

            <div class="labelSelect labelVoiceSelector">{{ $t('aiAvatar.voicesSelector') }}</div>
            <w-select class="voiceSelector" v-model="voiceSelected" size="small">
              <option v-for="voice in listOfVoice" :value="voice.value" :key="'voice_'+voice.value">
                {{ voice.label }}
              </option>
            </w-select>
            <w-button :icon="playingVoice?'stop':'volume-high'" :enabled="textToGenerate"
                      @click="previewVoice"
                      class="buttonPlayPreview" size="small" color="outlined"
            :loading="loadingVoice"> </w-button>
          </div>
          <w-input type="textarea" v-model="textToGenerate" @input="onTextInput" class="contentTextAreaAI"
                   :placeholder="$t('aiAvatar.content.placeholder')"
                   :maxlength="maxlengthOfCharacter">

          </w-input>
          <div v-if="isFreemium" class="upgradeMessage">{{ $t('aiAvatar.content.upgrade') }}</div>
          <div class="tipItem" v-if="isFreemium">
            <upgrade-plan-button size="small" color="secondary"/>
          </div>
        </b-carousel-item>
        <b-carousel-item class="generateStep">
          <CenterCenter>
            <div>
              <div class="progressGenerate">{{ $t('aiAvatar.generate.progress', {avatar: avatarSelected}) }}</div>
              <div class="pourcentageValue">{{ pourcentageGeneration.toFixed(2) }}%</div>
              <b-progress size="is-small" :value="pourcentageGeneration" type="is-primary" class="progressBar">
              </b-progress>
            </div>
          </CenterCenter>
        </b-carousel-item>
      </b-carousel>
    </div>
  </transition>

</template>

<script lang="ts">
import store from '@/store';
import Vue from 'vue';
import {Component, Watch} from 'vue-property-decorator';
import {SourceVideoType} from '@/enum/SourceVideoEnum';


import {
  AVATAR_AI_TEXT,
  CHANGE_STEP_AI_GENERATION,
  CLEAR_MAIN_MEDIAID, CLEAR_STICKER_MEDIAID,
  GENERATE_MAIN_MEDIAID,
  UPDATE_MAIN_RUNNING_STATE,
} from '@/store/recordingState/recordStateAction';
import VideoFilePlayer from '@/components/recorder/videoLayout/videoPlayer/VideoFilePlayer.vue';
import WSelect from "@/components/wrapper/w-select.vue";
import WButton from "@/components/wrapper/w-button.vue";
import WInput from "@/components/wrapper/w-input.vue";
import WIcon from "@/components/wrapper/w-icon.vue";
import UpgradePlanButton from "@/components/upgradePlan/UpgradePlanButton.vue";
import {isFreemium} from "@/utils/workspaceUtil";
import {
  AiavatarGenerationModel,
  Avatar, AvatarGender,
  AvatarStyle,
  AvatarVoiceAvatar, AvatarLanguage, getLabelForAvatar, LIST_OF_AVATAR, LIST_OF_BACKGROUND, Text2SpeachParams
} from "@/store/AIAvatar/aiavatarGenerationModel";
import {initWeetEditing} from "@/utils/createWeetUtil";
import {Media} from "@/store/media/mediaModel";
import {
  AIAVATAR_GENERATE,
  GENERATE_AVATAR_IN_PROGRESS,
  METADATA_MEDIA,
  REFRESH_MEDIA, TEXT_2_SPEECH_GENERATE, UPDATE_METADATA
} from "@/store/media/mediaAction";
import delay from "delay";
import {isMediaNeedRefresh} from "@/utils/util";
import {SAVE_TIMELINE_IF_NEED, TIMELINE_UNSAVED} from "@/store/timeLine/timeLineAction";
import CenterCenter from "@/components/layout/CenterCenter.vue";
import {alertErrorWithConfirmation} from "@/utils/dialog";
import WToolTip from "@/components/wrapper/w-toolTip.vue";
import {listOfVoice, listOfVoicesLanguages} from "@/utils/voiceUtil";
import {base64ToBlob} from "@/utils/audioUtil";
import {Howl} from 'howler';
import {SpeachEvent, SpeachEventAvatarDuration, SpeachEventName} from "@/speach/speachEvent";
import {MediaMetadata} from "@/store/upload/uploadAction";

@Component({
  components: {WToolTip, CenterCenter, UpgradePlanButton, WIcon, WInput, WButton, WSelect, VideoFilePlayer},
  computed: {},
})
export default class AIAvatarSource extends Vue {


  private listOfBackground = LIST_OF_BACKGROUND;

  private voiceSelected = AvatarVoiceAvatar.EN_US_AMANDA
  private langSelected: AvatarLanguage | null = this.listOfVoicesLangs[0].value;
  private avatarSelected = Avatar.MEG
  private styleSelected = AvatarStyle.FORMAL
  private versionAvatar:number=1;
  private backgroundSelected = this.listOfBackground[2];
  private gender = AvatarGender.FEMALE;
  private textToGenerate: string = "";
  private loadingVoice=false;
  private playingVoice=false;
  private audioPlayer:Howl|null=null;
  // value if the stream is setup
  private streamLoading: boolean = false;

  private pourcentageGeneration: number = 0;

  private MAX_LENGTH_FREEMIUM = 500;
  private MAX_LENGTH_PREMIUM = 5000;


  get listOfVoice() {
    return listOfVoice.filter((voice) => {
      return voice.gender === this.gender
    })
  }

  get listOfAvatar(){
    return LIST_OF_AVATAR.filter((avatar)=>{
      if(avatar.version>1 && !this.isExperimentalFeature){
        return false;
      }else{
        return true;
      }
    })
  }
  get listOfVoicesLangs() {
    const autoDetectOption = { label: "Auto detect", value: null };
    return [ autoDetectOption, ...listOfVoicesLanguages ];
  }

  get isExperimentalFeature(){
    return store.getters.isExperimentalFeature
  }
  get step() {
    return store.getters.getAiAvatarStep;
  }

  get avatarSelectedClass() {
    return this.avatarSelected + "_" + this.styleSelected;
  }

  get isFreemium(): boolean {
    return isFreemium()
  }

  public getLabelForAvatar(avatar:Avatar,style:AvatarStyle){
    return getLabelForAvatar(avatar,style);
  }


  public async mounted() {
    this.streamLoading = true;
    this.textToGenerate = store.getters.getAiAvatarText

  }

  get maxlengthOfCharacter() {
    if (this.isFreemium) {
      return this.MAX_LENGTH_FREEMIUM;
    } else {
      return this.MAX_LENGTH_PREMIUM;
    }
  }

  private onAvatarSelected(value: any) {
    this.avatarSelected = value.avatar;
    this.styleSelected = value.style
    this.versionAvatar=value.version;
    if (this.gender != value.gender) {
      this.gender = value.gender;
      this.voiceSelected = this.listOfVoice[0].value;
      this.langSelected = this.listOfVoicesLangs[0].value;
    }
  }

  private onBackgroundSelected(value:any){
    this.backgroundSelected=value;
  }

  private backToFirstStep() {
    store.dispatch(CHANGE_STEP_AI_GENERATION, 0)
  }

  private async initWeetEditing() {
    if (store.getters.getEditingWeetID === '') {
      await initWeetEditing()
    }
  }


  public generateMediaID() {
    if (store.getters.getMainRecordSource === SourceVideoType.NONE) {
      store.dispatch(CLEAR_MAIN_MEDIAID);
    }
    store.dispatch(GENERATE_MAIN_MEDIAID);
  }

  public updateRunningStatus(value: boolean) {
    store.dispatch(UPDATE_MAIN_RUNNING_STATE, value);
  }

  private onTextInput() {
    store.dispatch(AVATAR_AI_TEXT, this.textToGenerate);
  }

  private async stopPreviewVoice(){

    // STOP
    if(this.audioPlayer) {
      this.audioPlayer.stop();
      this.audioPlayer=null;
      this.playingVoice=false;
    }
  }
  private async previewVoice(){
    if(this.playingVoice){
      this.stopPreviewVoice();
      return;
    }else{
      this.stopPreviewVoice();
    }

    // STOP
    if(this.audioPlayer) {
      this.audioPlayer.stop();
      this.audioPlayer=null;
      this.playingVoice=false;
      return;
    }
    this.loadingVoice=true;
    const text2SpeachParams=new Text2SpeachParams()
    text2SpeachParams.text=this.textToGenerate;
    text2SpeachParams.voice=this.voiceSelected;
    text2SpeachParams.lang=this.langSelected;
    const base64=await store.dispatch(TEXT_2_SPEECH_GENERATE,text2SpeachParams);
    const url = URL.createObjectURL(base64ToBlob(base64,'audio/mp3'));
    this.loadingVoice=false;
    this.audioPlayer=new Howl({
      src: [url],
      autoplay: true,
      html5: true,
      onend:()=>{
        this.playingVoice=false;
      },
      volume: 1,
    });
    this.audioPlayer.play();
    this.playingVoice=true;
  }

  @Watch("step")
  public async launchAvatarGeneration() {
    this.stopPreviewVoice();
    if (this.step !== 2) { //if not step loading
      return;
    }
    if (this.textToGenerate) {
      try {
        store.dispatch(CHANGE_STEP_AI_GENERATION, 2)
        //this.reinit();
        await this.initWeetEditing(); // obtain a weet ID
        await store.dispatch(CLEAR_STICKER_MEDIAID);
        var mediaID = await store.dispatch(GENERATE_MAIN_MEDIAID);

        // create a media
        let media = new Media();
        media.mediaID = store.getters.getMainMediaId;
        media.type = SourceVideoType.AI_AVATAR;


        var params = new AiavatarGenerationModel(this.textToGenerate,
            this.avatarSelected, this.voiceSelected, this.langSelected, this.styleSelected,
            this.backgroundSelected.url,this.versionAvatar);
        media = await store.dispatch(AIAVATAR_GENERATE, {media, aiavatarParams: params});
        console.log("RefreshedMedia: ", media)
        await store.dispatch(TIMELINE_UNSAVED);
        media = await store.dispatch(REFRESH_MEDIA, media);

        // compute duration of voice over
        // TODO find a better way... because we paid 2 times for that
        const seconds=await this.getDurationForText();
        const metadMediaMetadata={status:"WAITING",duration:seconds};
        await store.dispatch(UPDATE_METADATA,metadMediaMetadata);

        // send time to speach
        const dataEvent=new SpeachEventAvatarDuration(store.getters.getEditingWeetID,seconds)
        window.parent.postMessage(new SpeachEvent(SpeachEventName.AIAVATAR_DURATION,dataEvent), "*")

        // // waiting media if need to be sync
        // while (isMediaNeedRefresh(media)) {
        //   await store.dispatch(TIMELINE_UNSAVED);
        //   await delay(2000)
        //   media = store.getters.getMediasForMediaId(media.mediaID);
        //   this.pourcentageGeneration = media.progress;
        // }


        store.dispatch(GENERATE_AVATAR_IN_PROGRESS, false)


        await store.dispatch(TIMELINE_UNSAVED);
        await store.dispatch(SAVE_TIMELINE_IF_NEED);
        store.dispatch(AVATAR_AI_TEXT, "");
        this.$emit('close');
      } catch (e) {
        console.error(e)
        store.dispatch(GENERATE_AVATAR_IN_PROGRESS, false)
        alertErrorWithConfirmation(this.$t('aiAvatar.content.error').toString()).then(() => {
          this.$emit('close');
        })
      }
    }
  }

  private async getDurationForText():Promise<number>{
    return new Promise(async (resolve,reject)=>{
      this.stopPreviewVoice();
      const text2SpeachParams=new Text2SpeachParams()
      text2SpeachParams.text=this.textToGenerate;
      text2SpeachParams.voice=this.voiceSelected;
      const base64=await store.dispatch(TEXT_2_SPEECH_GENERATE,text2SpeachParams);
      const url = URL.createObjectURL(base64ToBlob(base64,'audio/mp3'));
      this.audioPlayer=new Howl({
        src: [url],
        autoplay: true,
        html5: true,
        onload:()=>{
          this.audioPlayer.stop();
          resolve(this.audioPlayer.duration());
        },
        onend:()=>{
          this.playingVoice=false;
        },
        volume: 0,
      });
      this.audioPlayer.play();
    })

  }

  beforeDestroy(){
    this.stopPreviewVoice();
  }
}
</script>

<style lang="scss">
// no scope to manage textarea size
.contentTextAreaAI {
  .textarea {
    height: 170px;
    resize: none;
  }
}


</style>
<style lang="scss" scoped>
@import "@/scss/shadows.scss";

.AiEditorContainer {
  position: absolute;
  display: flex;
  width: 100%;
  animation-duration: 1s;
  border-radius: 8px;
  overflow: hidden;

  .carrouselAvatars {
    width: 100%;

    .aiAvatarSelection {
      width: 100%;
      min-height: 314px;
      background: var(--light);
      border-radius: 8px;

      .avatarParameter {
        width: 216px;
        text-align: right;
        padding-top: 32px;
        float: right;
        padding-right: 16px;

        .paramsSelect {
          margin-bottom: 24px;
          width: 200px;
        }

        .buttonNextStep {
          position: absolute;
          right: 16px;
          bottom: 16px;
        }

        .labelSelect {
          position: relative;
          text-align: left;
          font-size: 14px;
          color: var(--dark);
          justify-content: space-between;
          align-items: flex-end;
          display: flex;
        }
      }

      .avatarBackground {
        position: absolute;
        width: 100%;
        left: -100px;
        height: 100%;
        background-size: cover;
        border-radius: 8px;

        &::after {
          content: '';
          position: absolute;
          top: 0;
          right: 0;
          bottom: 0;
          left: 0;
          width: 100%;
          border-radius: 8px;
          background: linear-gradient(to right, rgba(255, 255, 255, 0) 20%, var(--light) 80%);
        }
      }

      .avatarPreview {
        position: absolute;
        width: 100%;
        height: 100%;
        background-repeat: no-repeat;
        background-size: 100%;
        background-position: -150px center;

        &.harry_business {
          background-image: url(@/assets/AIAvatar/HARRY-BUSINESS.png);
        }

        &.harry_casual {
          background-image: url(@/assets/AIAvatar/HARRY-CASUAL.png);
        }

        &.harry_youthful {
          background-image: url(@/assets/AIAvatar/HARRY-YOUTHFUL.png);
        }

        &.jeff_business {
          background-image: url(@/assets/AIAvatar/JEFF-BUSINESS.png);
        }

        &.jeff_formal {
          background-image: url(@/assets/AIAvatar/JEFF-FORMAL.png);
        }

        &.lori_casual {
          background-image: url(@/assets/AIAvatar/LORI-CASUAL.png);
        }

        &.lori_formal {
          background-image: url(@/assets/AIAvatar/LORI-FORMAL.png);
        }

        &.lori_graceful {
          background-image: url(@/assets/AIAvatar/LORI-GRACEFUL.png);
        }

        &.max_business {
          background-image: url(@/assets/AIAvatar/MAX-BUSINESS.png);
        }

        &.max_casual {
          background-image: url(@/assets/AIAvatar/MAX-CASUAL.png);
        }

        &.max_formal {
          background-image: url(@/assets/AIAvatar/MAX-FORMAL.png);
        }

        &.meg_business {
          background-image: url(@/assets/AIAvatar/MEG-BUSSINESS.png);
        }

        &.meg_casual {
          background-image: url(@/assets/AIAvatar/MEG-CASUAL.png);
        }

        &.meg_formal {
          background-image: url(@/assets/AIAvatar/MEG-FORMAL.png);
        }

        &.lisa_graceful-standing {
          background-image: url(@/assets/AIAvatar/LISA_GRACEFUL_STANDING.png);
        }

        &.lisa_casual-sitting {
          background-image: url(@/assets/AIAvatar/LISA_CASUAL_SITTING.png);
        }

        &.lisa_graceful-sitting {
          background-image: url(@/assets/AIAvatar/LISA_GRACEFUL_SITTING.png);
        }

        &.lisa_technical-standing {
          background-image: url(@/assets/AIAvatar/LISA_TECHNICAL_STANDING.png);
        }

        &.lisa_technical-sitting {
          background-image: url(@/assets/AIAvatar/LISA_TECHNICAL_SITTING.png);
        }
        // V2
        &.abigail_static{
          background-image: url(@/assets/AIAvatar/ABIGAIL.png);
        }
        &.arthur_static{
          background-image: url(@/assets/AIAvatar/ARTHUR.png);
        }
        &.darnel_static{
          background-image: url(@/assets/AIAvatar/DARNEL.png);
        }
        &.hannah_static{
          background-image: url(@/assets/AIAvatar/HANNAH.png);
        }
        &.marc_static{
          background-image: url(@/assets/AIAvatar/MARC.png);
        }
        &.isabella_static{
          background-image: url(@/assets/AIAvatar/ISABELLA.png);
        }
        &.jen_static{
          background-image: url(@/assets/AIAvatar/JEN.png);
        }
        &.marie_static{
          background-image: url(@/assets/AIAvatar/MARIE.png);
        }
        &.martha_static{
          background-image: url(@/assets/AIAvatar/MARTHA.png);
        }
        &.michelle_static{
          background-image: url(@/assets/AIAvatar/MICHELLE.png);
        }
        &.mike_static{
          background-image: url(@/assets/AIAvatar/MIKE.png);
        }
      }
    }

    .backgroundSelector, .avatarSelector {
      background: white;
      border-radius: 8px;
      padding: 2px 8px;
      margin-top: 4px;
      border: solid 1px var(--light1);
      min-width: 200px;
      font-size: 12px;
      cursor: pointer;
      color: var(--dark);

      .backgroundSelectorContent, .avatarSelectorContent {
        text-align: left;
        display: flex;
        justify-content: space-between;
        align-items: center;
        text-transform: capitalize;
      }
    }

    .backgroundSelection, .avatarSelection {
      display: flex;
      align-items: center;

      .backgroundSelectionImage, .avatarSelectionImage {
        border: 1px solid var(--light1);
        display: block;
        width: 32px;
        height: 32px;
        background: var(--light);
        background-position: center center;
        margin-right: 16px;
        border-radius: 4px;
        background-size: cover;
      }

      .backgroundSelectionLabel, .avatarSelectionLabel {
        font-size: 14px;
        flex: 1;
        color: var(--dark);
      }
      .backgroundSelected, .avatarSelectionSelected{
        margin-right: 0px;
      }
    }

    .aiAvatarContentEdition {
      min-height: 314px;
      padding: 16px;
      background: var(--light);
      border-radius: 8px;

      .labelTypeYourText {
        display: flex;
        align-items: center;
        margin-bottom: 8px;
        text-align: left;
        font-weight: 700;
        color: var(--black);

        .buttonBack {
          margin-right: 16px;
        }

        .iconInformation {
          margin-left: 8px;
          display: flex;
          margin-right: 56px;
        }

        .langSelector, .voiceSelector{
          margin-bottom: 0px;
          margin-left: 8px;
        }
        .langSelector {
          width: 167px;
        }
        .voiceSelector {
          width: 140px;
        }
        .buttonPlayPreview{
          margin-left: 8px;
          cursor: pointer;
        }
        .labelSelect {
          position: relative;
          text-align: left;
          font-size: 14px;
          color: var(--black);
          font-weight: 600;
          justify-content: space-between;
          align-items: flex-end;
          display: flex;
          &.labelVoiceSelector {
            margin-left: 16px;
          }
        }
      }

      .upgradeMessage {
        font-size: 14px;
        color: var(--light2);
      }

      .tipItem {
        position: absolute;
        right: 16px;
        bottom: 16px;
      }

      .contentTextAreaAI {
        margin-bottom: -16px;
      }

      .buttonValidateStep {
        position: absolute;
        bottom: 16px;
        right: 16px;
      }
    }

    .generateStep {
      min-height: 314px;
      padding: 16px;

      .progressGenerate {
        margin-bottom: 24px;
        font-size: 16px;
        min-width: 502px;

        &::first-letter {
          text-transform: uppercase;
        }
      }
    }
  }
}

</style>

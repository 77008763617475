<template>
  <div>
    <w-button
        @click="waitAndGoToSubtitleService"
        :rounded="rounded || circle"
        :size="size"
        :icon="icon|| 'subtitles-outline'"
        :icon-right="iconRight"
        :color="color"
        :disabled="!enabled"
        :loading="loading"
        :expanded="expanded"
        :tooltip="tooltip"
    >
      <slot></slot>
    </w-button>
    <div id="subtitleModalElement">
      <w-modal :active.sync="waiting" :modal="true">
        <CenterCenter>
          <div class="waitingPanel">
            <div class="labelPreparing">{{ $t('goToSubtitle.waiting') }}</div>
            <b-progress type="is-primary" :value="progress"/>
          </div>
        </CenterCenter>
      </w-modal>
    </div>
  </div>
</template>

<script lang="ts">
import {Component, Prop, Vue} from 'vue-property-decorator';
import WButton from '@/components/wrapper/w-button.vue';
import {Weet} from '@/store/weet/weetModel';
import WToolTip from '@/components/wrapper/w-toolTip.vue';
import WIcon from '@/components/wrapper/w-icon.vue';
import {goToSubtitleService} from "@/utils/weetUtil";
import {setActionEvent} from "@/utils/tracker";
import {ActionEvent} from "@/enum/TrackerEnum";
import WModal from "@/components/wrapper/w-modal.vue";
import CenterCenter from "@/components/layout/CenterCenter.vue";
import store from "@/store";
import {REFRESH_WEET_FOR_EDITING} from "@/store/myWeet/myWeetAction";
import delay from "delay";
import {recoverWeet} from "@/utils/weetRecover";
import {REGENERATE_EXPORTED_URL_WEET} from "@/store/weet/weetAction";

@Component({
  methods: {goToSubtitleService},
  components: {CenterCenter, WModal, WIcon, WToolTip, WButton},
})
export default class GoToSubtitleWeetButton extends Vue {
  @Prop()
  private weet!: Weet;

  @Prop({default: false})
  private rounded!: boolean;
  @Prop({default: 'default'})
  private size!: string;
  @Prop({default: ''})
  private icon!: string;
  @Prop({default: ''})
  private iconRight!: string;
  @Prop({default: 'primary'})
  private color!: string;
  @Prop({default: false})
  private circle!: boolean;
  @Prop({default: true})
  private enabled!: boolean;
  @Prop({default: false})
  private expanded!: boolean;
  @Prop({default: false})
  private loading!: boolean;
  @Prop({default: ""})
  private tooltip!: string;

  private progress: number = 0;
  private waiting: boolean = false;


  private mounted() {

    // we move the modal in body to avoid issue with z-index
    var body = document.body;
    var element = document.getElementById("subtitleModalElement");
    if (element) {
      body.appendChild(element);
    }
  }


  public async waitAndGoToSubtitleService() {

    setActionEvent(ActionEvent.record_advanced_setting);
    let weetToWait=this.weet;
    this.waiting = true;
    // recover weet if need
    weetToWait=await recoverWeet(weetToWait);
    await store.dispatch(REFRESH_WEET_FOR_EDITING, weetToWait.weetID);
    const timeBeforeAskToRegenerate=8000;
    const currentProgress=weetToWait.progressExport;
    let currentTimeWaiting=0;
    let askForRegeneration=true;
    // launch a generation of weet for subtitle if not
    if(weetToWait.exportAivailable|| weetToWait.progressExport<=0){
      store.dispatch(REGENERATE_EXPORTED_URL_WEET, {weetID: weetToWait.weetID})
      askForRegeneration=false;
    }
    while (!weetToWait.exportAivailable|| weetToWait.progressExport<100) {
      this.progress = this.weet.progressExport;
      await delay(2000);
      currentTimeWaiting+=2000;
      weetToWait=await store.dispatch(REFRESH_WEET_FOR_EDITING, weetToWait.weetID);
      if(askForRegeneration && currentTimeWaiting>timeBeforeAskToRegenerate && weetToWait.progressExport===currentProgress){
        // ask for regeneration
        askForRegeneration=false;
        store.dispatch(REGENERATE_EXPORTED_URL_WEET, {weetID: weetToWait.weetID})
      }
    }
    this.progress = 100;
    await delay(2000);
    goToSubtitleService(weetToWait)
  }

  private beforeDestroy() {
    document.getElementById("subtitleModalElement")?.remove();
  }
}
</script>
<style scoped lang="scss">
.waitingPanel {
  background: white;
  min-height: 100px;
  width: 100%;
  max-width: 400px;
  border-radius: 8px;
  padding: 48px;

  .labelPreparing {
    text-align: left;
    font-weight: 600;
    font-size: 16px;
    color: var(--black);
    margin-bottom: 8px;
  }
}
</style>

import { render, staticRenderFns } from "./VideoFilePlayer.vue?vue&type=template&id=328c1596&scoped=true"
import script from "./VideoFilePlayer.vue?vue&type=script&lang=ts"
export * from "./VideoFilePlayer.vue?vue&type=script&lang=ts"
import style0 from "./VideoFilePlayer.vue?vue&type=style&index=0&id=328c1596&prod&scoped=true&lang=scss"


/* normalize component */
import normalizer from "!../../../../../node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "328c1596",
  null
  
)

export default component.exports
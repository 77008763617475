import {AvatarGender, AvatarLanguage, AvatarVoiceAvatar} from "@/store/AIAvatar/aiavatarGenerationModel";

export const listOfVoice = [
    { label: 'Adam', value: AvatarVoiceAvatar.EN_US_ADAM, gender: AvatarGender.MALE },
    { label: 'Amanda', value: AvatarVoiceAvatar.EN_US_AMANDA, gender: AvatarGender.FEMALE },
    { label: 'Andrew', value: AvatarVoiceAvatar.EN_US_ANDREW, gender: AvatarGender.MALE },
    { label: 'Ava', value: AvatarVoiceAvatar.EN_US_AVA, gender: AvatarGender.FEMALE },
    { label: 'Brandon', value: AvatarVoiceAvatar.EN_US_BRANDON, gender: AvatarGender.MALE },
    { label: 'Brian', value: AvatarVoiceAvatar.EN_US_BRIAN, gender: AvatarGender.MALE },
    { label: 'Christopher', value: AvatarVoiceAvatar.EN_US_CHRISTOPHER, gender: AvatarGender.MALE },
    { label: 'Cora', value: AvatarVoiceAvatar.EN_US_CORA, gender: AvatarGender.FEMALE },
    { label: 'Davis', value: AvatarVoiceAvatar.EN_US_DAVIS, gender: AvatarGender.MALE },
    { label: 'Derek', value: AvatarVoiceAvatar.EN_US_DEREK, gender: AvatarGender.MALE },
    { label: 'Dustin', value: AvatarVoiceAvatar.EN_US_DUSTIN, gender: AvatarGender.MALE },
    { label: 'Emma', value: AvatarVoiceAvatar.EN_US_EMMA, gender: AvatarGender.FEMALE },
    { label: 'Evelyn', value: AvatarVoiceAvatar.EN_US_EVELYN, gender: AvatarGender.FEMALE },
    { label: 'Lewis', value: AvatarVoiceAvatar.EN_US_LEWIS, gender: AvatarGender.MALE },
    { label: 'Lola', value: AvatarVoiceAvatar.EN_US_LOLA, gender: AvatarGender.FEMALE },
    { label: 'Nancy', value: AvatarVoiceAvatar.EN_US_NANCY, gender: AvatarGender.FEMALE },
    { label: 'Phoebe', value: AvatarVoiceAvatar.EN_US_PHOEBE, gender: AvatarGender.FEMALE },
    { label: 'Samuel', value: AvatarVoiceAvatar.EN_US_SAMUEL, gender: AvatarGender.MALE },
    { label: 'Serena', value: AvatarVoiceAvatar.EN_US_SERENA, gender: AvatarGender.FEMALE },
    { label: 'Steffan', value: AvatarVoiceAvatar.EN_US_STEFFAN, gender: AvatarGender.MALE },
    { label: 'Alloy', value: AvatarVoiceAvatar.EN_US_ALLOY_TURBO, gender: AvatarGender.MALE },
    { label: 'Echo', value: AvatarVoiceAvatar.EN_US_ECHO_TURBO, gender: AvatarGender.MALE },
    { label: 'Fable', value: AvatarVoiceAvatar.EN_US_FABLE_TURBO, gender: AvatarGender.NEUTRAL },
    { label: 'Nova', value: AvatarVoiceAvatar.EN_US_NOVA_TURBO, gender: AvatarGender.FEMALE },
    { label: 'Onyx', value: AvatarVoiceAvatar.EN_US_ONYX_TURBO, gender: AvatarGender.MALE },
    { label: 'Shimmer', value: AvatarVoiceAvatar.EN_US_SHIMMER_TURBO, gender: AvatarGender.FEMALE },
    { label: 'Ada', value: AvatarVoiceAvatar.EN_GB_ADA, gender: AvatarGender.FEMALE },
    { label: 'Ollie', value: AvatarVoiceAvatar.EN_GB_OLLIE, gender: AvatarGender.MALE },
    { label: 'Seraphina', value: AvatarVoiceAvatar.DE_DE_SERAPHINA, gender: AvatarGender.FEMALE },
    { label: 'Florian', value: AvatarVoiceAvatar.DE_DE_FLORIAN, gender: AvatarGender.MALE },
    { label: 'Arabella', value: AvatarVoiceAvatar.ES_ES_ARABELLA, gender: AvatarGender.FEMALE },
    { label: 'Isidora', value: AvatarVoiceAvatar.ES_ES_ISIDORA, gender: AvatarGender.FEMALE },
    { label: 'Tristan', value: AvatarVoiceAvatar.ES_ES_TRISTAN, gender: AvatarGender.MALE },
    { label: 'Ximena', value: AvatarVoiceAvatar.ES_ES_XIMENA, gender: AvatarGender.FEMALE },
    { label: 'Lucien', value: AvatarVoiceAvatar.FR_FR_LUCIEN, gender: AvatarGender.MALE },
    { label: 'Vivienne', value: AvatarVoiceAvatar.FR_FR_VIVIENNE, gender: AvatarGender.FEMALE },
    { label: 'Remy', value: AvatarVoiceAvatar.FR_FR_REMY, gender: AvatarGender.MALE },
    { label: 'Alessio', value: AvatarVoiceAvatar.IT_IT_ALESSIO, gender: AvatarGender.MALE },
    { label: 'Giuseppe', value: AvatarVoiceAvatar.IT_IT_GIUSEPPE, gender: AvatarGender.MALE },
    { label: 'Isabella', value: AvatarVoiceAvatar.IT_IT_ISABELLA, gender: AvatarGender.FEMALE },
    { label: 'Marcello', value: AvatarVoiceAvatar.IT_IT_MARCELLO, gender: AvatarGender.MALE },
    { label: 'Masaru', value: AvatarVoiceAvatar.JA_JP_MASARU, gender: AvatarGender.MALE },
    { label: 'Hyunsu', value: AvatarVoiceAvatar.KO_KR_HYUNSU, gender: AvatarGender.MALE },
    { label: 'Macerio', value: AvatarVoiceAvatar.PT_BR_MACERIO, gender: AvatarGender.MALE },
    { label: 'Thalita', value: AvatarVoiceAvatar.PT_BR_THALITA, gender: AvatarGender.FEMALE },
    { label: 'Xiaoxiao', value: AvatarVoiceAvatar.ZH_CN_XIAOXIAO, gender: AvatarGender.FEMALE },
    { label: 'Xiaochen', value: AvatarVoiceAvatar.ZH_CN_XIAOCHEN, gender: AvatarGender.FEMALE },
    { label: 'Xiaoyu', value: AvatarVoiceAvatar.ZH_CN_XIAOYU, gender: AvatarGender.FEMALE },
    { label: 'Yunyi', value: AvatarVoiceAvatar.ZH_CN_YUNYI, gender: AvatarGender.MALE },
    { label: 'Yunfan', value: AvatarVoiceAvatar.ZH_CN_YUNFAN, gender: AvatarGender.MALE },
    { label: 'Yunxiao', value: AvatarVoiceAvatar.ZH_CN_YUNXIAO, gender: AvatarGender.MALE }
];

export const listOfVoicesLanguages = [
    { label: "Afrikaans", value: AvatarLanguage.AF_ZA },
    { label: "Albanian", value: AvatarLanguage.SQ_AL },
    { label: "Amharic", value: AvatarLanguage.AM_ET },
    { label: "Arabic", value: AvatarLanguage.AR_EG },
    { label: "Armenian", value: AvatarLanguage.HY_AM },
    { label: "Azerbaijani", value: AvatarLanguage.AZ_AZ },
    { label: "Bahasa Indonesian", value: AvatarLanguage.ID_ID },
    { label: "Bangla", value: AvatarLanguage.BN_BD },
    { label: "Basque", value: AvatarLanguage.EU_ES },
    { label: "Bengali", value: AvatarLanguage.BN_IN },
    { label: "Bosnian", value: AvatarLanguage.BS_BA },
    { label: "Bulgarian", value: AvatarLanguage.BG_BG },
    { label: "Burmese", value: AvatarLanguage.MY_MM },
    { label: "Catalan", value: AvatarLanguage.CA_ES },
    { label: "Chinese Cantonese", value: AvatarLanguage.ZH_HK },
    { label: "Chinese Mandarin", value: AvatarLanguage.ZH_CN },
    { label: "Chinese Taiwanese", value: AvatarLanguage.ZH_TW },
    { label: "Croatian", value: AvatarLanguage.HR_HR },
    { label: "Czech", value: AvatarLanguage.CS_CZ },
    { label: "Danish", value: AvatarLanguage.DA_DK },
    { label: "Dutch", value: AvatarLanguage.NL_NL },
    { label: "English", value: AvatarLanguage.EN_US },
    { label: "Estonian", value: AvatarLanguage.ET_EE },
    { label: "Filipino", value: AvatarLanguage.FIL_PH },
    { label: "Finnish", value: AvatarLanguage.FI_FI },
    { label: "French", value: AvatarLanguage.FR_FR },
    { label: "Galician", value: AvatarLanguage.GL_ES },
    { label: "Georgian", value: AvatarLanguage.KA_GE },
    { label: "German", value: AvatarLanguage.DE_DE },
    { label: "Greek", value: AvatarLanguage.EL_GR },
    { label: "Hebrew", value: AvatarLanguage.HE_IL },
    { label: "Hindi", value: AvatarLanguage.HI_IN },
    { label: "Hungarian", value: AvatarLanguage.HU_HU },
    { label: "Icelandic", value: AvatarLanguage.IS_IS },
    { label: "Irish", value: AvatarLanguage.GA_IE },
    { label: "Italian", value: AvatarLanguage.IT_IT },
    { label: "Japanese", value: AvatarLanguage.JA_JP },
    { label: "Javanese", value: AvatarLanguage.JV_ID },
    { label: "Kannada", value: AvatarLanguage.KN_IN },
    { label: "Kazakh", value: AvatarLanguage.KK_KZ },
    { label: "Khmer", value: AvatarLanguage.KM_KH },
    { label: "Korean", value: AvatarLanguage.KO_KR },
    { label: "Lao", value: AvatarLanguage.LO_LA },
    { label: "Latvian", value: AvatarLanguage.LV_LV },
    { label: "Lithuanian", value: AvatarLanguage.LT_LT },
    { label: "Macedonian", value: AvatarLanguage.MK_MK },
    { label: "Malay", value: AvatarLanguage.MS_MY },
    { label: "Malayalam", value: AvatarLanguage.ML_IN },
    { label: "Maltese", value: AvatarLanguage.MT_MT },
    { label: "Mongolian", value: AvatarLanguage.MN_MN },
    { label: "Nepali", value: AvatarLanguage.NE_NP },
    { label: "Norwegian Bokmål", value: AvatarLanguage.NB_NO },
    { label: "Pashto", value: AvatarLanguage.PS_AF },
    { label: "Persian", value: AvatarLanguage.FA_IR },
    { label: "Polish", value: AvatarLanguage.PL_PL },
    { label: "Portuguese", value: AvatarLanguage.PT_BR },
    { label: "Romanian", value: AvatarLanguage.RO_RO },
    { label: "Russian", value: AvatarLanguage.RU_RU },
    { label: "Serbian", value: AvatarLanguage.SR_RS },
    { label: "Sinhala", value: AvatarLanguage.SI_LK },
    { label: "Slovak", value: AvatarLanguage.SK_SK },
    { label: "Slovene", value: AvatarLanguage.SL_SI },
    { label: "Somali", value: AvatarLanguage.SO_SO },
    { label: "Spanish", value: AvatarLanguage.ES_ES },
    { label: "Sundanese", value: AvatarLanguage.SU_ID },
    { label: "Swahili", value: AvatarLanguage.SW_KE },
    { label: "Swedish", value: AvatarLanguage.SV_SE },
    { label: "Tamil", value: AvatarLanguage.TA_IN },
    { label: "Telugu", value: AvatarLanguage.TE_IN },
    { label: "Thai", value: AvatarLanguage.TH_TH },
    { label: "Turkish", value: AvatarLanguage.TR_TR },
    { label: "Ukrainian", value: AvatarLanguage.UK_UA },
    { label: "Urdu", value: AvatarLanguage.UR_PK },
    { label: "Uzbek", value: AvatarLanguage.UZ_UZ },
    { label: "Vietnamese", value: AvatarLanguage.VI_VN },
    { label: "Welsh", value: AvatarLanguage.CY_GB },
    { label: "Zulu", value: AvatarLanguage.ZU_ZA }
];

export const getAvatarLangEnumFromValue = (value: string): AvatarLanguage | null => {
    return listOfVoicesLanguages.find(lang => lang.value === value)?.value || null;
}

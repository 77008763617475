<template>
  <div>
    <WeetBlockerPlayer v-if="shouldBlockWeetCreation && !isSpeach" :isNewWeet="true"/>
    <div v-else class="mainRecorderLayout">
      <div class="layout">

        <!-- Waring message if connexion problem -->
        <Transition name="zoom">
          <div class="warningInformation" v-show="isConnectionProblem">{{
              $t('createWeet.connection.problem')
            }}
          </div>
        </Transition>

        <!-- STEP One Create Content -->
        <div steptour="containerRecord" class="screen" key="screen1" v-if="step===1 && selectedWorkspaceID">
          <CreateWeetRecordStep ref="recordingStep" @next="goToSettingStep" @countDown="onCountDown" @close="onClose"/>
        </div>
        <!-- STEP TWO TITLE -->
        <div class="screen" key="screen2" v-if="step===2">
          <CreateWeetPreviewStep ref="createWeetPreviewComponent" @back="returnToEdit" @end="goToTheRecorderWeet"
                                 @advancedEditing="goToAdvancedEditing"
          @informSpeach="onClose"/>
        </div>

        <!-- STEP TWO THREE -->
        <div class="screen" key="screen2" v-if="step===3">
          <CreateWeetAdvanceEditingPreviewStep @back="returnToPreview"/>
        </div>

        <Transition name="zoom">
          <w-button data-test="recorderCloseButton" class="close" style="animation-duration: 0.2s"
                    v-show="isCloseAivailable" :circle="true" color="light"
                    icon="close" size="small"
                    @click="onClose"/>
        </Transition>
        <ThumbnailAutomaticGenerator/>
        <!-- Download Panel -->
        <div v-if="isSpeach">
          <DownloadOptionPanel :weet="myWeet" :active.sync="downloadPanelOption"/>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import Component from 'vue-class-component';
import {Prop, Vue} from 'vue-property-decorator';
import store from '@/store';
import {TIMER_RECORD} from '@/store/timeLine/timeLineAction';
import {
  RECORD_MAIN_VIDEO_TYPE,
  RECORD_STICKER_VIDEO_TYPE, SAVE_FLAT_WEET,
  SET_CURRENT_EDITING_WEET,
  SHOW_POPUP_CREATION,
  STOP_RECORDING,
} from '@/store/recordingState/recordStateAction';

import TimeLineRecorder from '@/components/recorder/timeLine/TimeLineRecorder.vue';
import TimerRecorder from '@/components/recorder/timer/TimerRecorder.vue';
import WButton from '@/components/wrapper/w-button.vue';
import VideoSource from '@/components/recorder/videoLayout/source/VideoSource.vue';
import WModal from '@/components/wrapper/w-modal.vue';
import {
  LOAD_WEET_FOR_EDITING,
  NOTIFY_WEET_UPDATE,
  NotifyUpdateParams,
  SET_CHANNELS_TO_WEET
} from '@/store/myWeet/myWeetAction';
import {sendMessageToExtention} from '@/utils/extentionUtil';
import ValidateWeetButton from '@/components/actionButton/ValidateWeetButton.vue';
import StickerLayoutSource from '@/components/recorder/sticker/StickerLayoutSource.vue';
import WIcon from '@/components/wrapper/w-icon.vue';
import RecordSettingPanel from '@/components/recorder/setup/RecordSettingPanel.vue';
import NotificationPanel from '@/components/notifications/NotificationPanel.vue';
import {setActionEvent, setPageEvent} from '@/utils/tracker';
import {ActionEvent, PageEvent} from '@/enum/TrackerEnum';
import WToolTip from '@/components/wrapper/w-toolTip.vue';
import SettingsButton from '@/components/recorder/setup/SettingsButton.vue';
import ChooseScreen from '@/components/recorder/videoLayout/chooseScreen/ChooseScreen.vue';
import WInput from '@/components/wrapper/w-input.vue';
import EndWeetButton from '@/components/actionButton/EndWeetButton.vue';
import WError from '@/components/wrapper/w-error.vue';
import {Weet} from '@/store/weet/weetModel';
import WField from '@/components/wrapper/w-field.vue';
import PopUpTitle from '@/components/title/PopUpTitle.vue';
import UserAvatar from '@/components/user/UserAvatar.vue';
import {Media} from '@/store/media/mediaModel';
import {copyLink, createUnsavedEditingWeet, isWeetEquals, reinitRecorder} from '@/utils/weetUtil';
import RecorderStartPauseButton from '@/components/recorder/button/RecorderStartPauseButton.vue';
import TimeLine from '@/components/recorder/timeLine/TimeLine.vue';
import ShareCommentPolicy from '@/components/share/ShareCommentPolicy.vue';
import ShareVisibilityPolicy from '@/components/share/ShareVisibilityPolicy.vue';
import {TimeEvent} from '@/store/timeLine/timeEvent';
import {
  EXTERNAL_TOOL_INIT,
  EXTERNAL_TOOL_SEND_MESSAGE,
  NOTIFY_WEET_CREATION_FROM_EXTERNALTOOL
} from '@/store/integration/integrationAction';
import {NotifyWeetCreation} from '@/store/integration/integrationModel';
import {goToPlayPageIfNeeded, openExternalToolDesktopApp} from '@/utils/integrationUtil';
import {getThumbnailURL} from '@/utils/util';
import WHelp from '@/components/wrapper/w-help.vue';
import ScreenLayoutSelector from '@/components/recorder/videoLayout/ScreenLayoutSelector.vue';
import CreateWeetRecordStep from '@/components/recorder/createWeetSubComponent/CreateWeetRecordStep.vue';
import CreateWeetPreviewStep from '@/components/recorder/createWeetSubComponent/CreateWeetPreviewStep.vue';
import {SourceVideoType} from '@/enum/SourceVideoEnum';
import {TimeEventType} from '@/enum/TimeEventType';
import delay from 'delay';
import router from '@/router';
import {alertErrorWithConfirmation, alertMessageWithConfirmation, inform} from '@/utils/dialog';
import ThumbnailAutomaticGenerator from '@/components/recorder/thumbnailGenerator/ThumbnailAutomaticGenerator.vue';
import {isFreemiumQuotaReached, selectWorkspace} from "@/utils/workspaceUtil";
import {AdvancedEditingMode} from "@/enum/AdvancedEditingMode";
import {SELECT_MODE} from "@/store/advancedEditing/advancedEditingAction";
import {GET_WORKSPACE_WEET_COUNT} from "@/store/workspace/workspaceAction";
import CreateWeetAdvanceEditingPreviewStep
  from "@/components/recorder/createWeetSubComponent/CreateWeetAdvanceEditingPreviewStep.vue";
import WeetBlockerPlayer from '@/components/play/player/weetPlayerSubComponent/WeetBlockerPlayer.vue';
import {checkWorkspaceSelection} from "@/utils/createWeetUtil";
import {isSpeach} from "@/utils/speachUtils";
import {SpeachEvent, SpeachEventName, SpeachModification} from "@/speach/speachEvent";
import {on} from "screenfull";
import DownloadOptionPanel from "@/components/download/DownloadOptionPanel.vue";
import {recoverWeet} from "@/utils/weetRecover";
import {REGENERATE_EXPORTED_URL_WEET} from "@/store/weet/weetAction";
@Component({
  methods: {on},
  components: {
    DownloadOptionPanel,
    WeetBlockerPlayer,
    CreateWeetAdvanceEditingPreviewStep,
    ThumbnailAutomaticGenerator,
    CreateWeetPreviewStep,
    CreateWeetRecordStep,
    ScreenLayoutSelector,
    WHelp,
    ShareVisibilityPolicy,
    ShareCommentPolicy,
    TimeLine,
    RecorderStartPauseButton,
    UserAvatar,
    PopUpTitle,
    WField,
    WError,
    EndWeetButton,
    WInput,
    ChooseScreen,
    SettingsButton,
    WToolTip,
    NotificationPanel,
    RecordSettingPanel,
    WIcon,
    StickerLayoutSource,
    ValidateWeetButton,
    WModal,
    VideoSource,
    WButton,
    TimerRecorder,
    TimeLineRecorder,
  },
})
export default class CreateWeet extends Vue {
  @Prop({default: ''})
  public weetID!: string;

  @Prop({default: false})
  public reset!: boolean;

  @Prop({default: false})
  public disableClosePopup!: boolean;


  private recordCountDown: number = 0;

  private isReady: boolean = false;

  private step: number = 1;

  private oldTimeLine: TimeEvent[] = [];

  private downloadPanelOption: boolean = false;

  get isCloseAivailable(): boolean {
    return this.recordCountDown === 0 && !store.getters.isSychronisationInProgress && !store.getters.timelineNeedToBeSaved
        && !this.isRecording && !this.disableClosePopup && !store.getters.isAnnotationInteractionSaveInProgress && !store.getters.isAvatarGenerateInProgress;
  }

  get isSpeach(){
    return isSpeach();
  }

  get isConnectionProblem(): boolean {
    return store.getters.isConnectionProblem;
  }

  get getTimeLine(): TimeEvent[] {
    return store.getters.getTimeEvent;
  }

  get getMedias(): Media[] {
    return store.getters.getMedias;
  }

  get getTranscriptMedia(): Media {
    return store.getters.getMedias;
  }

  get shouldBlockWeetCreation(): boolean {
    // if store.getter.getEditingWeetID is empty, we are in creation Mode
    return isFreemiumQuotaReached() && !store.getters.getEditingWeetID;
  }

  private onCountDown(value: number) {
    this.recordCountDown = value;
  }

  private emitStep() {
    this.$emit('step', this.step);
  }

  private goToRecordingStep() {
    this.step = 1;
    this.emitStep();

    this.$nextTick(()=>{
      // @ts-ignore
      this.$refs.recordingStep.reinitAIAvatar();
    })
  }

  public goToSettingStep() {
    this.step = 2;
    // reinit
    store.dispatch(RECORD_STICKER_VIDEO_TYPE, SourceVideoType.WEBCAM);
    store.dispatch(RECORD_MAIN_VIDEO_TYPE, SourceVideoType.NONE);
    this.emitStep();
  }

  private goToAdvancedEditing(value: AdvancedEditingMode) {
    this.step = 3;
    // reinit
    store.dispatch(RECORD_STICKER_VIDEO_TYPE, SourceVideoType.WEBCAM);
    store.dispatch(RECORD_MAIN_VIDEO_TYPE, SourceVideoType.NONE);
    this.emitStep();
    store.dispatch(SELECT_MODE, value);
  }


  private async onClose() {
    store.dispatch(SELECT_MODE, AdvancedEditingMode.NONE);
    let isModified = true;
    if (store.getters.getFlattenWeet.length > 0 && isWeetEquals(JSON.parse(store.getters.getFlattenWeet), createUnsavedEditingWeet(store.getters.getEditingWeetID))) {
      isModified = false;
    }
    if(isSpeach()){
      if(store.getters.getEditingWeetID) {
        window.parent.postMessage(new SpeachEvent(SpeachEventName.SEND_WEET_ID, new SpeachModification(store.getters.getEditingWeetID, isModified,store.getters.getTimerRecord)), "*")
      }else{
        window.parent.postMessage(new SpeachEvent(SpeachEventName.SEND_WEET_ID, new SpeachModification('', false,store.getters.getTimerRecord)), "*")
      }
    }else{
      if(isModified && store.getters.getEditingWeetID){
        store.dispatch(REGENERATE_EXPORTED_URL_WEET, {weetID: store.getters.getEditingWeetID, download: false})
      }
      await this.notifyIsUpdate();
      await this.saveChannelsIsUpdate();
      this.$emit('close');
    }
  }

  private returnToEdit() {
    this.goToRecordingStep();
  }

  private returnToPreview() {
    this.goToSettingStep();
  }

  get isRecording(): boolean {
    return store.getters.isPauseAivailable;
  }

  get myWeet(): Weet | undefined {
    return store.getters.getMyWeet(store.getters.getEditingWeetID);
  }

  private async beforeCreate() {
    if(!await checkWorkspaceSelection()){
      this.$emit('close');
      await alertMessageWithConfirmation(this.$t('createWeetComponent.noWorkspaceError.message').toString(), this.$t('createWeetComponent.noWorkspaceError.title').toString())
      this.$router.push({name: 'CreateWorkspace'});
    }

    store.dispatch(RECORD_MAIN_VIDEO_TYPE, SourceVideoType.AI_AVATAR)
  }

  private async mounted() {
    setPageEvent(PageEvent.page_record_inapp);
    const currentWeetEditing = store.getters.getEditingWeetID;

    if (!currentWeetEditing) {
      // send a fake weetID to inform the extention we are in record
      sendMessageToExtention(SET_CURRENT_EDITING_WEET, 'fake');
    }
    this.emitStep();
    store.dispatch(EXTERNAL_TOOL_INIT);
    let weet: Weet | undefined;
    if (this.weetID !== '') {
      weet = await store.dispatch(LOAD_WEET_FOR_EDITING, this.weetID) as Weet;
      weet=await recoverWeet(weet);
    } else if (currentWeetEditing !== undefined && currentWeetEditing !== '') {
      try {
        weet = await store.dispatch(LOAD_WEET_FOR_EDITING, currentWeetEditing);
        if(weet) {
          weet = await recoverWeet(weet);
        }
      } catch (err: any) {
        if (err.status === 403) {
          if (store.getters.isAuthenticated) {
            router.push({name: 'AccessDenied'});
          }
          await alertErrorWithConfirmation(this.$t('createWeetComponent.denied.message').toString(),
              this.$t('createWeetComponent.denied.title').toString());
          store.dispatch(SHOW_POPUP_CREATION, false);

          return;
        }
      }
    }
    if (weet) {
      store.dispatch(SAVE_FLAT_WEET,weet);
      this.oldTimeLine = weet.timeLine;
    }

    if(weet && store.getters.getAdvancedEditingMode!==AdvancedEditingMode.NONE){
      if(AdvancedEditingMode.DOWNLOAD===store.getters.getAdvancedEditingMode){
        this.downloadPanelOption=true;
      }else{
        this.goToAdvancedEditing(store.getters.getAdvancedEditingMode);
      }
    }

    this.isReady = true;


    // DEBUG resilience Webcam
    // await delay(1000);
    // this.$emit('close');
  }



  get getTimerRecord(): number {
    return store.getters.getTimerRecord;
  }

  public async goToTheRecorderWeet() {
    setActionEvent(ActionEvent.record_button_validate, {duration_of_weet: store.getters.getTimerRecord});
    // share weet

    // renit time of record for the plugin, because it's finished
    const weetID = store.getters.getEditingWeetID;
    store.dispatch(TIMER_RECORD, 0);
    sendMessageToExtention(TIMER_RECORD, 0);
    const weethumbnail = this.thumbnailSEO;

    // Copy the Weet link to clipboard
    if (this.myWeet) {
      copyLink(this.myWeet);
      inform(this.$t("shareLinkButton.copy.confirmation").toString());
    }

    // Create the message to send to external tool to notify Weet creation
    let title = '';
    if (this.myWeet) {
      title = this.myWeet.title;
    }

    // If external tool, await and send message to close the current page instead of go to play page
    const externalToolMessage = {
      id: 'weetCreated',
      embedUrl: `/embed/${weetID}`,
      weetPlayUrl: `/play/${weetID}`,
      title: title,
      thumbnail: weethumbnail
    };
    await store.dispatch(EXTERNAL_TOOL_SEND_MESSAGE, externalToolMessage);


    // Notify weet creation to external bot if creation is from external tool
    const weetCreatedNotification: NotifyWeetCreation = {
      externalToolReference: store.getters.getPrepopulatedExternalToolReference,
      externalTool: store.getters.getExternalTool,
      weetId: weetID
    };
    store.dispatch(NOTIFY_WEET_CREATION_FROM_EXTERNALTOOL, weetCreatedNotification);

    // notify if the weet was updated
    await this.notifyIsUpdate();

    // add channel to weet, and notify user if new channels
    await this.saveChannelsIsUpdate();

    // Suggest user to open external tool if creation was done from external tool
    openExternalToolDesktopApp();
    if (this.myWeet) {
      goToPlayPageIfNeeded(this.myWeet, weethumbnail);
    }

    // Re-count the number of free weets to update next call
    store.dispatch(GET_WORKSPACE_WEET_COUNT);

    // Hide the weet creation pop up
    store.dispatch(SHOW_POPUP_CREATION, false);
  }

  private async saveChannelsIsUpdate() {
    const weetID = store.getters.getEditingWeetID;
    // create channelIDS:
    const channelIDs: string[] = [];
    for (const channel of createUnsavedEditingWeet(weetID).channels) {
      channelIDs.push(channel.channelID);
    }
    if (channelIDs.length > 0) {
      await store.dispatch(SET_CHANNELS_TO_WEET, {weetID, channelIDs});
    }
  }

  private async notifyIsUpdate() {
    const weetID = store.getters.getEditingWeetID;

    // now we notify if need the updat of the weet
    if (this.oldTimeLine.length > 0 && this.myWeet) {
      const timesToNotify: number[] = [];

      for (const te of this.myWeet.timeLine as TimeEvent[]) {
        if (te.type === TimeEventType.MEDIA_PLAY) {
          let mustNotify = true;
          for (const oldTe of this.oldTimeLine as TimeEvent[]) {
            if (oldTe.time === te.time) {
              mustNotify = false;
              break;
            }
          }
          if (mustNotify) {
            timesToNotify.push(te.time);
          }
        }
      }
      if (timesToNotify.length > 0) {
        const params = new NotifyUpdateParams(weetID, timesToNotify);
        await store.dispatch(NOTIFY_WEET_UPDATE, params);
      }
    }
  }

  get selectedWorkspaceID(): string | null {
    return store.getters.getSelectedWorkspaceID;
  }

  get thumbnailSEO(): string {
    // we search the event with 0
    if (this.myWeet !== undefined && this.myWeet.timeLine.length > 0) {
      const timeEvent = this.myWeet.timeLine[0];
      let mediaId = '';
      if (timeEvent.mainMedia.mediaID !== undefined && timeEvent.mainMedia.mediaID !== '') {
        mediaId = timeEvent.mainMedia.mediaID;
      } else {
        mediaId = timeEvent.secondMedia.mediaID;
      }
      // get the jogID for media
      for (const media of this.myWeet.medias) {
        if (media.mediaID === mediaId) {
          return getThumbnailURL(media.jobID, true);
        }
      }
    }
    return '';
  }


  private beforeDestroy() {
    // we wait the next tick to reinit
    // it allow some email to be send by example
    this.$nextTick(() => {
      reinitRecorder();
      sendMessageToExtention(TIMER_RECORD, 0);
      sendMessageToExtention(STOP_RECORDING);
      sendMessageToExtention(SET_CURRENT_EDITING_WEET, '');
    });

  }

}
</script>
<style lang="scss">

b.primary {
  color: var(--primary);
  font-weight: 900;
  font-size: 18px;
}

b.red {
  color: var(--red);
  font-weight: 900;
  font-size: 18px;
}
</style>
<style scoped lang="scss">

@import '@/scss/shadows.scss';


.close {
  border: 2px solid white;
  border-radius: 50px;
  position: absolute;
  right: -16px;
  top: -16px;
}

.mainRecorderLayout {
  background: #FFF;
  position: absolute;
  width: 100%;
  height: 100%;
  border-radius: 8px;

  .layout {
    width: 100%;
    min-height: 100%;
    height: 100%;


    .warningInformation {
      position: absolute;
      top: 8px;
      color: var(--red);
      // border: solid 1px var(--red);
      border-radius: 4px;
      font-size: 12px;
      height: 20px;
      width: calc(100% - 128px);
      margin-left: 64px;
    }
  }

}

.buttonSaveEnd {
  position: absolute;
  bottom: 32px;
  right: 32px;
}

</style>

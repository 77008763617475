import axios from 'axios';
import {BACK_ENDPOINT} from '@/store/conf/api';
import {
    ADD_USERS_TO_WORKSPACE,
    COULD_LEAVE_WORKSPACE,
    CREATE_WORKSPACE,
    DELETE_USER_FROM_WORKSPACE,
    DELETE_WORKSPACE,
    FILTER_WORKSPACE_BY_NAME,
    GET_WORKSPACE_USERS_COUNT,
    GET_MY_STATUS_IN_WORKSPACE,
    GET_MY_WORKSPACE,
    GET_MY_WORKSPACES_COUNT,
    GET_MY_WORKSPACES_LIST,
    GET_WORKSPACE_USER_LIST,
    GET_WORKSPACE_WEET_COUNT,
    JOIN_WORKSPACE,
    LEAVE_WORKSPACE,
    REINIT_WORKSPACE_USERS_SEARCHED,
    REQUEST_UPGRADE_WORKSPACE,
    RESET_WORKSPACE_USER_LIST,
    SEARCH_WORKSPACE_USERS_BY_EMAIL,
    SET_SETTINGWORKSPACE,
    SET_WORKSPACE_USER,
    UPDATE_WORKSPACE,
    UPDATE_WORKSPACE_ICON,
    UPDATE_WORKSPACE_USER_ROLE, GET_WORKSPACE_CREATORS_COUNT, SET_MY_LOGO_HEADER
} from '@/store/workspace/workspaceAction';
import {PaginationWrapper} from '@/store/generic/paginationWrapper';
import Vue from 'vue';
import {
    InviteUsersParam,
    LeaveWorkspaceEnum,
    SettingWorkspace,
    SettingWorkspaceKey,
    SettingWorkspaceParams,
    Workspace,
    WorkspaceCount,
    WorkspaceGetUserParams,
    WorkspaceUserManageInbound,
    WorkspaceUsersStatus
} from '@/store/workspace/workspaceModel';
import store from '@/store';
import {User} from '@/store/user/userModel';
import {
    WorkspaceUserCountRepresentation,
    WorkspaceWeetCountRepresentation
} from '@/store/payment/paymentModel';
import {Theme} from '@/theme';

const workspaceAPI = BACK_ENDPOINT + '/workspaces';

export default {
    state: {
        myWorkspaces: {},
        workspaceUsers: {},
        workspacesRights: {},
        currentPage: 0,
        totalOfWorkspaces: 0,
        currentUsersPage: 0,
        totalOfWorkspaceUsers: 0,
        totalOfWorkspaceCreators: 0,
        totalOfFilteredWorkspaceUsers: 0,
        usersSearched: {},
        myWorkspacesSearched: {},
        totalOfWeetInWorkspace: -1,
        totalOfAvailableWeetInWorkspace: -1,
        logoHeader: '',
    },
    mutations: {
        [SET_MY_LOGO_HEADER]: (state: any, url: string) => {
            state.logoHeader = url;
        },
        [SEARCH_WORKSPACE_USERS_BY_EMAIL]: (state: any, users: User[]) => {
            state.usersSearched = {};
            users.forEach((user) => {
                Vue.set(state.usersSearched, user.userID, user);
            });
        },
        [GET_MY_WORKSPACES_LIST]: (state: any, data: PaginationWrapper) => {
            if (data.page === 0) {
                state.myWorkspaces = {};
            }
            const workspaces = data.data as Workspace[];
            for (const i in workspaces) {
                if (workspaces[i] !== undefined) {
                    Vue.set(state.myWorkspaces, workspaces[i].workspaceID, workspaces[i]);
                }
            }
            state.currentPage = data.page;
            state.totalOfWorkspaces = data.count;
        },
        [GET_WORKSPACE_USERS_COUNT]: (state: any, data: WorkspaceUserCountRepresentation) => {
            state.totalOfWorkspaceUsers = data.count;
        },
        [GET_WORKSPACE_CREATORS_COUNT]: (state: any, data: WorkspaceUserCountRepresentation) => {
            state.totalOfWorkspaceCreators = data.count;
        },
        [GET_WORKSPACE_WEET_COUNT]: (state: any, data: WorkspaceWeetCountRepresentation) => {
            state.totalOfWeetInWorkspace = data.count;
            state.totalOfAvailableWeetInWorkspace = data.countAvailableForFreemium;
        },
        [GET_WORKSPACE_USER_LIST]: (state: any, data: PaginationWrapper) => {
            if (data.data.length === 0) {
                state.workspaceUsers = [];
            } else {
                const userStatus = data.data as WorkspaceUsersStatus[];
                for (const userStatu of userStatus) {
                    Vue.set(state.workspaceUsers, userStatu.user.userID, userStatu);
                }
            }
            state.currentUsersPage = data.page;
            state.totalOfFilteredWorkspaceUsers = data.count;
        },
        [RESET_WORKSPACE_USER_LIST]: (state: any) => {
            state.workspaceUsers = {};
            state.currentUsersPage = 0;
            state.totalOfFilteredWorkspaceUsers = 0;
        },
        [REINIT_WORKSPACE_USERS_SEARCHED]: (state: any) => {
            state.usersSearched = {};
        },
        [SET_WORKSPACE_USER]: (state: any, data: WorkspaceUsersStatus) => {
            Vue.set(state.workspaceUsers, data.user.userID, data);
        },
        [UPDATE_WORKSPACE]: (state: any, data: Workspace) => {
            Vue.set(state.myWorkspaces, data.workspaceID, data);
        },
        [FILTER_WORKSPACE_BY_NAME]: (state: any, data: Workspace[]) => {
            state.groupSearched = {};
            data.forEach((workspace) => {
                Vue.set(state.myWorkspacesSearched, workspace.workspaceID, workspace);
            });
        },
        [LEAVE_WORKSPACE]: (state: any, workspaceID: string) => {
            Vue.delete(state.myWorkspaces, workspaceID);
            Vue.delete(state.workspacesRights, workspaceID);
        },
    },
    getters: {
        getMyWorkspaces: (state: any): Workspace[] => {
            return (Object.values(state.myWorkspaces) as Workspace[]).sort((a, b) => {
                    return b.createdUTC - a.createdUTC;
                }
            );
        },
        getMyWorkspacesFiltered: (state: any): Workspace[] => {
            return Object.values(state.myWorkspacesSearched) as Workspace[];
        },
        getUsersSearched: (state: any): User[] => {
            return Object.values(state.usersSearched) as User[];
        },
        getSelectedWorkspace: (state: any, getters: any): Workspace => {
            return state.myWorkspaces[getters.getSelectedWorkspaceID];
        },
        getCurrentPageWorkspace: (state: any): number => {
            return state.currentPage;
        },
        getTotalOfWorkspace: (state: any): number => {
            return state.totalOfWorkspaces;
        },
        getWorkspace: (state: any) => (workspaceID: string): Workspace => {
            return state.myWorkspaces[workspaceID];
        },
        getMyWorkspaceUsers: (state: any): WorkspaceUsersStatus[] => {
            return Object.values(state.workspaceUsers);
        },
        getCurrentWorkspaceUserPage: (state: any): number => {
            return state.currentUsersPage;
        },
        getTotalOfFilteredWorkspaceUsers: (state: any): number => {
            return state.totalOfFilteredWorkspaceUsers;
        },
        getTotalOfWorkspaceUsers: (state: any): number => {
            return state.totalOfWorkspaceUsers;
        },
        getTotalOfWorkspaceCreators: (state: any): number => {
            return state.totalOfWorkspaceCreators;
        },
        getWorkspaceSetting: (state: any) => (workspaceID: string, key: SettingWorkspaceKey): SettingWorkspace | null => {
            const settings=(state.myWorkspaces[workspaceID] as Workspace)?.settings;
            if(settings){
                return settings.find((sett: SettingWorkspace) => sett.key === key) || null;
            }
            return null;
        },
        getExcedingWeet: (state: any, getters: any) => (workspaceID: string): number | null => {
            const quota = getters.getWorkspaceSetting(workspaceID, SettingWorkspaceKey.WEET_FREEMIUM_QUOTA);
            if (quota === null || state.totalOfWeetInWorkspace === null) {
                return null;
            } else {
                return state.totalOfWeetInWorkspace - parseInt(quota.value, 10);
            }
        },
        getFreemiumQuotas: (state: any, getters: any) => (workspaceID: string): number | null => {
            const quota = getters.getWorkspaceSetting(workspaceID, SettingWorkspaceKey.WEET_FREEMIUM_QUOTA);
            if (quota != null) {
                return parseInt(quota.value, 10);
            } else {
                return null;
            }
        },
        isFreemiumQuotaReached: (state: any, getters: any) => (workspaceID: string): boolean => {
            const quota = getters.getWorkspaceSetting(workspaceID, SettingWorkspaceKey.WEET_FREEMIUM_QUOTA);
            if (quota != null) {
                return state.totalOfAvailableWeetInWorkspace >= quota.value;
            } else {
                return false;
            }
        },
        getLogoHeader: (state: any, getters: any): string => {
            return state.logoHeader;
        },
    },
    actions: {
        [SET_MY_LOGO_HEADER]({commit, dispatch}: { commit: any, dispatch: any }, url: number = 0) {
            commit(SET_MY_LOGO_HEADER, url);
        },
        async [GET_MY_WORKSPACES_LIST]({commit, dispatch}: { commit: any, dispatch: any }, page: number = 0) {
            return await axios({
                url: workspaceAPI,
                method: 'GET',
                params: {page: page},
            }).then((resp) => {
                commit(GET_MY_WORKSPACES_LIST, resp.data);
            }).catch((err) => {
                if (err.data) {
                    err = err.data;
                }
                throw err;
            });
        },
        async [FILTER_WORKSPACE_BY_NAME]({commit, dispatch}: { commit: any, dispatch: any }, name: string) {
            if (name) {
                return await axios({
                    url: `${workspaceAPI}/search/${name}`,
                    method: 'GET',
                }).then((resp) => {
                    commit(FILTER_WORKSPACE_BY_NAME, resp.data as Workspace[]);
                }).catch((err) => {
                    if (err.data) {
                        err = err.data;
                    }
                    throw err;
                });
            }
        },
        async [ADD_USERS_TO_WORKSPACE](
            {commit, dispatch}: { commit: any, dispatch: any },
            inviteUsersParam: InviteUsersParam
        ) {
            const workspace = store.getters.getSelectedWorkspaceID;
            if (!workspace) {
                throw new Error('impossible to find the workspace');
            }
            return await axios({
                url: `${workspaceAPI}/${workspace}/users`,
                data: inviteUsersParam,
                method: 'POST',
            }).then((resp) => {
                return resp;
            }).catch((err) => {
                if (err.data) {
                    err = err.data;
                }
                throw err;
            });
        },
        async [GET_WORKSPACE_USER_LIST](
            {commit, dispatch}: { commit: any, dispatch: any },
            params: WorkspaceGetUserParams
        ) {
            const workspace = store.getters.getSelectedWorkspaceID;
            if (!workspace) {
                throw new Error('impossible to find the workspace');
            }
            params.workspaceID = workspace;
            return await axios({
                url: `${workspaceAPI}/${workspace}/users`,
                method: 'GET',
                params: {page: params.page, userFilter: params.userFilter, statusFilter: params.statusFilter},
            }).then((resp) => {
                commit(RESET_WORKSPACE_USER_LIST);
                commit(GET_WORKSPACE_USER_LIST, resp.data);
            }).catch((err) => {
                if (err.data) {
                    err = err.data;
                }
                commit(RESET_WORKSPACE_USER_LIST);
                throw err;
            });
        },
        async [SEARCH_WORKSPACE_USERS_BY_EMAIL](
            {commit, dispatch}: { commit: any, dispatch: any },
            email: string = ''
        ) {
            const workspace = store.getters.getSelectedWorkspaceID;
            if (!workspace) {
                throw new Error('impossible to find the workspace');
            }
            if (email) {
                return await axios({
                    url: `${workspaceAPI}/${workspace}/users/search/${email}`,
                    method: 'GET',
                }).then((resp) => {
                    commit(SEARCH_WORKSPACE_USERS_BY_EMAIL, resp.data as User[]);
                }).catch((err) => {
                    if (err.data) {
                        err = err.data;
                    }
                    throw err;
                });
            }
        },
        async [COULD_LEAVE_WORKSPACE](
            {commit, dispatch}: { commit: any, dispatch: any }
        ): Promise<LeaveWorkspaceEnum> {
            const workspace = store.getters.getSelectedWorkspaceID;
            if (!workspace) {
                throw new Error('impossible to find the workspace');
            }
            return await axios({
                url: `${workspaceAPI}/${workspace}/leave`,
                method: 'GET',
            }).then((resp) => {
                return resp.data as LeaveWorkspaceEnum;
            }).catch((err) => {
                if (err.data) {
                    err = err.data;
                }
                throw err;
            });
        },
        async [GET_WORKSPACE_USERS_COUNT](
            {commit, dispatch}: { commit: any, dispatch: any }
        ): Promise<WorkspaceUserCountRepresentation> {
            const workspace = store.getters.getSelectedWorkspaceID;
            if (!workspace) {
                throw new Error('impossible to find the workspace');
            }
            return await axios({
                url: `${workspaceAPI}/${workspace}/users/count`,
                method: 'GET',
            }).then((resp) => {
                commit(GET_WORKSPACE_USERS_COUNT, resp.data);
                return resp.data as WorkspaceUserCountRepresentation;
            }).catch((err) => {
                if (err.data) {
                    err = err.data;
                }
                throw err;
            });
        },
        async [GET_WORKSPACE_CREATORS_COUNT](
            {commit, dispatch}: { commit: any, dispatch: any }
        ): Promise<WorkspaceUserCountRepresentation> {
            const workspace = store.getters.getSelectedWorkspaceID;
            if (!workspace) {
                throw new Error('impossible to find the workspace');
            }
            return await axios({
                url: `${workspaceAPI}/${workspace}/creators/count`,
                method: 'GET',
            }).then((resp) => {
                commit(GET_WORKSPACE_CREATORS_COUNT, resp.data);
                return resp.data as WorkspaceUserCountRepresentation;
            }).catch((err) => {
                if (err.data) {
                    err = err.data;
                }
                throw err;
            });
        },
        async [GET_WORKSPACE_WEET_COUNT](
            {commit, dispatch}: { commit: any, dispatch: any },
        ): Promise<WorkspaceWeetCountRepresentation> {
            const workspace = store.getters.getSelectedWorkspaceID;
            if (!workspace) {
                throw new Error('impossible to find the workspace');
            }
            return await axios({
                url: `${workspaceAPI}/${workspace}/weets/count`,
                method: 'GET',
            }).then((resp) => {
                commit(GET_WORKSPACE_WEET_COUNT, resp.data);
                return resp.data as WorkspaceWeetCountRepresentation;
            }).catch((err) => {
                if (err.data) {
                    err = err.data;
                }
                throw err;
            });
        },
        async [UPDATE_WORKSPACE_USER_ROLE](
            {
                commit,
                dispatch
            }: { commit: any, dispatch: any },
            workspaceUserManageInbounds: WorkspaceUserManageInbound[]
        ) {
            const workspace = store.getters.getSelectedWorkspaceID;
            if (!workspace) {
                throw new Error('impossible to find the workspace');
            }
            return await axios({
                url: `${workspaceAPI}/${workspace}/users`,
                method: 'PUT',
                data: {workspaceUserStatus: workspaceUserManageInbounds},
            }).then((resp) => {
                const res = resp.data as WorkspaceUsersStatus[];
                res.forEach((updateWkUser) => {
                    commit(SET_WORKSPACE_USER, updateWkUser);
                });
            }).catch((err) => {
                if (err.data) {
                    err = err.data;
                }
                throw err;
            });
        },
        async [DELETE_USER_FROM_WORKSPACE](
            {commit, dispatch}: { commit: any, dispatch: any }, listOfUserID: string[]
        ) {
            const workspace = store.getters.getSelectedWorkspaceID;
            if (!workspace) {
                throw new Error('impossible to find the workspace');
            }

            return await axios({
                url: `${workspaceAPI}/${workspace}/users`,
                method: 'DELETE',
                data: {listOfUserID: listOfUserID},
            }).then(() => {
                const workspaceUsersParams = new WorkspaceGetUserParams(store.getters.getCurrentWorkspaceUserPage);
                dispatch(GET_WORKSPACE_USER_LIST, workspaceUsersParams);
            }).catch((err) => {
                if (err.data) {
                    err = err.data;
                }
                throw err;
            });
        },
        async [UPDATE_WORKSPACE_ICON]({commit, dispatch}: { commit: any, dispatch: any }, {
            blob,
            header
        }: { blob: Blob, header: boolean }) {
            const workspaceID = store.getters.getSelectedWorkspaceID;
            if (!workspaceID) {
                throw new Error('impossible to find the workspace');
            }
            const formData = new FormData();
            formData.append('image', blob);
            return await axios({
                url: `${workspaceAPI}/${workspaceID}/image`,
                method: 'POST',
                params: {header},
                data: formData,
                headers: {
                    'Content-Type': 'multipart/form-data',
                    'Content-Disposition': 'form-data; name=media',
                },
            }).then((resp) => {
                const workspace = resp.data;
                commit(UPDATE_WORKSPACE, workspace);
                return workspace.imageURL;
            }).catch((err) => {
                if (err.data) {
                    err = err.data;
                }
                throw err;
            });
        },
        async [UPDATE_WORKSPACE](
            {commit, dispatch}: { commit: any, dispatch: any },
            {
                name, theme
            }: { name: string | null, theme: Theme | null }
        ) {
            const workspaceID = store.getters.getSelectedWorkspaceID;
            if (!workspaceID) {
                throw new Error('impossible to find the workspace');
            }
            return await axios({
                url: `${workspaceAPI}/${workspaceID}`,
                method: 'PUT',
                data: {name, theme},
            }).then((resp) => {
                const workspace = resp.data as Workspace;
                commit(UPDATE_WORKSPACE, workspace);
            }).catch((err) => {
                if (err.data) {
                    err = err.data;
                }
                throw err;
            });
        },
        async [SET_SETTINGWORKSPACE]({
                                         commit,
                                         dispatch
                                     }: { commit: any, dispatch: any },
                                     settingWorkspace: SettingWorkspace) {
            await axios({
                url: `${workspaceAPI}/${settingWorkspace.workspaceID}/settings`,
                method: 'PUT',
                data: {key: settingWorkspace.key, value: settingWorkspace.value} as SettingWorkspaceParams
            }).then((res) => {
                const data = res.data as Workspace;
                commit(UPDATE_WORKSPACE, data);
            }).catch((err) => {
                if (err.data) {
                    err = err.data;
                }
                throw err;
            });
        },
        async [DELETE_WORKSPACE](
            {commit, dispatch}: { commit: any, dispatch: any }) {
            const workspaceID = store.getters.getSelectedWorkspaceID;
            if (!workspaceID) {
                throw new Error('impossible to find the workspace');
            }
            return await axios({
                url: `${workspaceAPI}/${workspaceID}`,
                method: 'DELETE',
            }).then(() => {
                dispatch(GET_MY_WORKSPACES_LIST);
            }).catch((err) => {
                if (err.data) {
                    err = err.data;
                }
                throw err;
            });
        },
        async [LEAVE_WORKSPACE](
            {commit, dispatch}: { commit: any, dispatch: any }) {
            const workspaceID = store.getters.getSelectedWorkspaceID;
            if (!workspaceID) {
                throw new Error('impossible to find the workspace');
            }
            return await axios({
                url: `${workspaceAPI}/${workspaceID}/leave`,
                method: 'DELETE',
            }).then(() => {
                commit(RESET_WORKSPACE_USER_LIST);
                commit(LEAVE_WORKSPACE, workspaceID);
            }).catch((err) => {
                if (err.data) {
                    err = err.data;
                }
                throw err;
            });
        },
        async [REINIT_WORKSPACE_USERS_SEARCHED]({commit, dispatch}: { commit: any, dispatch: any }) {
            commit(REINIT_WORKSPACE_USERS_SEARCHED);
        },
        async [CREATE_WORKSPACE]({commit, dispatch}: { commit: any, dispatch: any }, {
            blob,
            name
        }: { blob: Blob, name: string }) {
            const formData = new FormData();
            formData.append('image', blob);
            formData.append('name', name);
            return await axios({
                url: `${workspaceAPI}`,
                method: 'POST',
                data: formData,
                headers: {
                    'Content-Type': 'multipart/form-data',
                    'Content-Disposition': 'form-data; name=media',
                },
            }).then((resp) => {
                const workspace = resp.data;
                commit(UPDATE_WORKSPACE, workspace);
                return workspace;
            }).catch((err) => {
                if (err.data) {
                    err = err.data;
                }
                throw err;
            });
        },
        async [REQUEST_UPGRADE_WORKSPACE]({commit, dispatch, getters}: { commit: any, dispatch: any, getters: any },
                                          workspace: string) {
            await axios({
                url: `${workspaceAPI}/${workspace}/request`,
                method: 'POST',
            }).catch((err) => {
                throw err;
            });

        },
        async [GET_MY_WORKSPACE]({
                                     commit,
                                     dispatch
                                 }: { commit: any, dispatch: any },
                                 workspaceID: string): Promise<Workspace> {
            return await axios({
                url: `${workspaceAPI}/${workspaceID}`,
                method: 'GET',
            }).then((resp) => {
                return resp.data as Workspace;
            }).catch((err) => {
                if (err.data) {
                    err = err.data;
                }
                throw err;
            });
        },
        async [JOIN_WORKSPACE]({
                                   commit,
                                   dispatch,
                                   getters
                               }: { commit: any, dispatch: any, getters: any },
                               workspace: string) {
            await axios({
                url: `${workspaceAPI}/${workspace}/join`,
                method: 'PUT',
            }).then(() => {
                dispatch(GET_MY_WORKSPACES_LIST);
            }).catch((err) => {
                throw err;
            });
        },
        async [GET_MY_WORKSPACES_COUNT]({
                                            commit,
                                            dispatch
                                        }: { commit: any, dispatch: any },
                                        withPending: boolean = false): Promise<WorkspaceCount> {
            return await axios({
                url: `${workspaceAPI}/count`,
                params: {withPending: withPending},
                method: 'GET',
            }).then((resp) => {
                return resp.data as WorkspaceCount;
            }).catch((err) => {
                if (err.data) {
                    err = err.data;
                }
                throw err;
            });
        }, async [GET_MY_STATUS_IN_WORKSPACE]({
                                                  commit,
                                                  dispatch
                                              }: { commit: any, dispatch: any },
                                              workspaceID: string): Promise<WorkspaceUsersStatus> {
            return await axios({
                url: `${workspaceAPI}/${workspaceID}/me`,
                method: 'GET',
            }).then((resp) => {
                return resp.data as WorkspaceUsersStatus;
            }).catch((err) => {
                if (err.data) {
                    err = err.data;
                }
                throw err;
            });
        },
    },

};
